import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';
import proposalMonthPriceGetter from './proposalMonthPriceGetter';
import proposalDayPriceGetter from './proposalDayPriceGetter';

const proposalPriceGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  if (!params.data) return 0;

  switch (params.data.pricingBasis) {
    case 'MONTH':
      return proposalMonthPriceGetter(params);
    case 'DAY':
      return proposalDayPriceGetter(params);
    default:
      return 0;
  }
};

export default proposalPriceGetter;
