import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import {
  OfferItem,
  OfferPricingBasis,
} from '../../../../shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ITEM_GROUPS_QUERY_KEY,
  PRICE_FACTOR,
} from '../../../../shared/constants';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import { createItemGroup } from '../../services/itemGroups';
import GroupingGrid from './GroupingGrid';
import { ClientDatabaseItemGroup } from '../../../../shared/types/offers';
import { isSalesItem } from '../../../../shared/helpers/isSalesItem';
import Dates from '../Steps/Components/Dates';
import GroupDialogRadioButtons from './GroupDialogRadioButtons';

interface NewGroupDialogProps {
  items: OfferItem[];
  handleMultipleRowValueUpdates: (selectedRows: OfferItem[]) => void;
  leasePeriodStart: Date;
  leasePeriodEnd: Date;
  selectedRows: OfferItem[];
  setSelectedRows: (selectedRows: OfferItem[]) => void;
}

const NewGroupDialog: React.FC<NewGroupDialogProps> = ({
  items,
  handleMultipleRowValueUpdates,
  leasePeriodStart,
  leasePeriodEnd,
  selectedRows,
  setSelectedRows,
}) => {
  const initialGroupState: ClientDatabaseItemGroup = {
    groupName: '',
    totalPrice: null,
    quantity: 1,
    pricingBasis: 'MONTH',
    showItemsInPdf: true,
    leasePeriodStart: leasePeriodStart,
    leasePeriodEnd: leasePeriodEnd,
    type: 'RENTAL',
  };

  const [open, setOpen] = useState(false);
  const [groupedItems, setGroupedItems] = useState<OfferItem[]>([]);
  const [remainingItems, setRemainingItems] = useState<OfferItem[]>(
    [],
  );
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [groupState, setGroupState] = useState(initialGroupState);
  const [createButtonDisabled, setCreateButtonDisabled] =
    useState(false);

  const { setNotification } = useContext(NotificationProvider);
  const queryClient = useQueryClient();

  const { isPending, mutate: createNewItemGroup } = useMutation({
    mutationFn: createItemGroup,
    onSuccess: (data) => {
      const itemGroup = queryClient.setQueryData(
        [ITEM_GROUPS_QUERY_KEY],
        data,
      );
      if (itemGroup) {
        const newRows = groupedItems.map((item: OfferItem) => ({
          ...item,
          itemGroupId: (itemGroup as ClientDatabaseItemGroup).id,
          pricingBasis: groupState.pricingBasis,
          leasePeriodStart: groupState.leasePeriodStart,
          leasePeriodEnd: groupState.leasePeriodEnd,
        }));
        handleMultipleRowValueUpdates(
          newRows.map((item) => ({
            ...item,
          })),
        );
      }
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'success',
        message: 'Uusi ryhmä luotu onnistuneesti!',
      });
    },
    onSettled: () => {
      setGroupState(initialGroupState);
      setOpen(!isPending);
    },
    onError: () => {
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Uuden ryhmän luonti epäonnistui!',
      });
    },
  });

  useEffect(() => {
    const filteredGroupedItems = selectedRows;
    const filteredRemainingItems = items.filter((item) => {
      if (groupState.type === 'SALES') {
        return (
          isSalesItem(item.type) &&
          !filteredGroupedItems.some(
            (selectedItem) =>
              selectedItem.offerItemId === item.offerItemId,
          )
        );
      } else {
        return (
          item.type === 'RENTAL' &&
          !filteredGroupedItems.some(
            (selectedItem) =>
              selectedItem.offerItemId === item.offerItemId,
          )
        );
      }
    });

    setGroupedItems(filteredGroupedItems);
    setRemainingItems(filteredRemainingItems);

    const hasRental = selectedRows.some(
      (item) => item.type === 'RENTAL',
    );
    const hasSales = selectedRows.some((item) =>
      isSalesItem(item.type),
    );

    if (hasRental && !hasSales) {
      setCreateButtonDisabled(false);
      setGroupState({
        ...groupState,
        type: 'RENTAL',
      });
    } else if (!hasRental && hasSales) {
      setCreateButtonDisabled(false);
      setGroupState({
        ...groupState,
        type: 'SALES',
      });
    } else {
      if (selectedRows.length > 0) {
        setCreateButtonDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, items, groupState.type]);

  useEffect(() => {
    const calculateTotalPrice = () => {
      return groupedItems.reduce((accumulator, currentItem) => {
        const price =
          (groupState.pricingBasis === 'MONTH'
            ? currentItem.monthPrice ?? 0
            : currentItem.dayPrice ?? 0) * currentItem.quantity;
        return accumulator + price / PRICE_FACTOR;
      }, 0);
    };

    setTotalPrice(parseFloat(calculateTotalPrice().toFixed(2)));
  }, [groupedItems, groupState]);

  const handleGroupNameChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setGroupState({
      ...groupState,
      groupName: e.target.value,
    });
  };

  const handleTypeChange = (value: string) => {
    setGroupedItems([]);
    setSelectedRows([]);
    setGroupState({
      ...groupState,
      type: value,
    });
  };

  const handleShowItemsInPdfChange = (value: boolean) => {
    setGroupState({
      ...groupState,
      showItemsInPdf: value,
    });
  };

  const handlePricingBasisChange = (basis: 'DAY' | 'MONTH') => {
    setGroupState({
      ...groupState,
      pricingBasis: basis,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    date.setHours(4);

    setGroupState({
      ...groupState,
      leasePeriodStart: date,
    });
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    date.setHours(4);

    setGroupState({
      ...groupState,
      leasePeriodEnd: date,
    });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSaveButtonClick = () => {
    createNewItemGroup({
      ...groupState,
      pricingBasis: groupState.pricingBasis as OfferPricingBasis,
      totalPrice: totalPrice * PRICE_FACTOR,
    });
    setGroupState({
      ...groupState,
      groupName: '',
    });
  };

  const isSaveButtonDisabled = () => {
    const currentDate = new Date();
    const startDate = new Date(groupState.leasePeriodStart);
    const endDate = new Date(groupState.leasePeriodEnd);

    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return (
      groupedItems.length === 0 ||
      groupState.groupName === '' ||
      (groupState.type === 'RENTAL' &&
        (!groupState.leasePeriodStart ||
          !groupState.leasePeriodEnd ||
          startDate < currentDate ||
          endDate <= startDate ||
          endDate < currentDate))
    );
  };

  return (
    <>
      <DefaultTooltip
        title={
          createButtonDisabled
            ? 'Et voi luoda ryhmää jossa on sekaisin vuokratuotteita ja myynti- ja palvelutuotteita'
            : 'Luo uusi tuoteryhmä'
        }
      >
        <Button
          startIcon={<LibraryAddIcon />}
          onClick={() => setOpen(!open)}
          sx={{ height: 26 }}
          size="small"
          variant="outlined"
          disabled={createButtonDisabled}
        >
          Luo uusi ryhmä
        </Button>
      </DefaultTooltip>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth="xl"
        PaperProps={{
          style: {
            maxHeight: '95vh',
          },
        }}
      >
        <DialogTitle>Luo uusi ryhmä</DialogTitle>
        <Fade in={open}>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                gap: 16,
              }}
            >
              <Box>
                <TextField
                  label="Ryhmän nimi"
                  placeholder="Ryhmän nimi"
                  sx={{ my: 1, minWidth: 300 }}
                  onChange={handleGroupNameChange}
                  value={groupState.groupName}
                />
                <Box sx={{ my: 1 }}>
                  <Dates
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    startDate={groupState.leasePeriodStart}
                    endDate={groupState.leasePeriodEnd}
                    minStartDate={new Date()}
                    startDateLabel={'Tuoteryhmän vuokra-aika alkaa'}
                    endDateLabel={'Tuoteryhmän vuokra-aika loppuu'}
                    disabled={groupState.type === 'SALES'}
                  />
                </Box>
              </Box>
              <Box>
                <GroupDialogRadioButtons
                  label="Ryhmän hinnoitteluperuste:"
                  value={groupState.pricingBasis}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handlePricingBasisChange(
                      event.target.value as 'DAY' | 'MONTH',
                    )
                  }
                  options={[
                    { label: 'Päivähinta', value: 'DAY' },
                    { label: 'Kuukausihinta', value: 'MONTH' },
                  ]}
                  disabled={groupState.type === 'SALES'}
                />
                <GroupDialogRadioButtons
                  label="Ryhmän tuotteiden tyyppi:"
                  value={groupState.type}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleTypeChange(event.target.value)
                  }
                  options={[
                    { label: 'Vuokratuotteet', value: 'RENTAL' },
                    {
                      label: 'Myynti- ja palvelutuotteet',
                      value: 'SALES',
                    },
                  ]}
                />
                <GroupDialogRadioButtons
                  label="Näytä tuotteet PDF:llä:"
                  value={groupState.showItemsInPdf.toString()}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleShowItemsInPdfChange(
                      event.target.value === 'true',
                    )
                  }
                  options={[
                    { label: 'Kyllä', value: 'true' },
                    { label: 'Ei', value: 'false' },
                  ]}
                />
                <Box>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormLabel focused>
                        Yhden ryhmän hinta:
                      </FormLabel>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <Typography marginY={1}>
                        {totalPrice} €
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box>
              <GroupingGrid
                groupedItems={groupedItems}
                remainingItems={remainingItems}
                setGroupedItems={setGroupedItems}
                pricingBasis={
                  groupState.pricingBasis as OfferPricingBasis
                }
                groupName={groupState.groupName}
                groupType={groupState.type}
              />
            </Box>
            <DialogActions
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button color="inherit" onClick={handleDialogClose}>
                Peruuta
              </Button>
              <Button
                disabled={isSaveButtonDisabled()}
                variant="contained"
                color="primary"
                onClick={handleSaveButtonClick}
              >
                Tallenna
              </Button>
            </DialogActions>
          </DialogContent>
        </Fade>
      </Dialog>
    </>
  );
};

export default NewGroupDialog;
