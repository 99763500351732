import { calculateTotalPrice } from '../../../shared/calculateTotalPrice';
import { PRICE_FACTOR } from '../../../shared/constants';
import { isSalesItem } from '../../../shared/helpers/isSalesItem';
import { OfferItem } from '../../../shared/types';

export const calculateTotalRentalPrice = (
  offerSheetItems: OfferItem[],
) => {
  return offerSheetItems.reduce((acc, item) => {
    if (!isSalesItem(item.type)) {
      let groupQuantity = 1;
      if (item.itemGroup) {
        groupQuantity = item.itemGroup.quantity;
      }
      return (
        acc +
        calculateTotalPrice(item, 'TWODECIMALS') *
          PRICE_FACTOR *
          groupQuantity
      );
    }
    return acc;
  }, 0);
};

export const calculateTotalSalesPrice = (
  offerSheetItems: OfferItem[],
) => {
  return offerSheetItems.reduce((acc, item) => {
    if (isSalesItem(item.type) && item.monthPrice) {
      let groupQuantity = 1;
      if (item.itemGroup) {
        groupQuantity = item.itemGroup.quantity;
      }
      return acc + item.monthPrice * item.quantity * groupQuantity;
    }
    return acc;
  }, 0);
};
