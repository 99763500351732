import React from 'react';
import { Box } from '@mui/system';
import { DatePicker } from './DatePicker';
import { HandleDateChange } from '../InitialInformationStep';

interface DatesProps {
  onStartDateChange: HandleDateChange;
  onEndDateChange: HandleDateChange;
  startDate: Date;
  endDate: Date;
  minStartDate: Date;
  startDateLabel: string;
  endDateLabel: string;
  disabled?: boolean;
}
const Dates: React.FC<DatesProps> = ({
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  minStartDate,
  startDateLabel,
  endDateLabel,
  disabled = false,
}) => {
  const [startDateInput, setStartDateInput] =
    React.useState<Date | null>(startDate);
  const [endDateInput, setEndDateInput] = React.useState<Date | null>(
    endDate,
  );

  // Handle manual (non calendar) input on blur
  const handleOnBlur = (inputDate: Date) => {
    if (endDateInput && endDateInput < inputDate) {
      setEndDateInput(inputDate);
    }
  };

  // Control the enddate input on startdate change
  const onStartDateChangeWrapped = (value: Date | null) => {
    if (endDateInput && value && endDateInput < value) {
      setEndDateInput(value);
    }
    onStartDateChange(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      sx={{
        width: '100%',
        maxWidth: '320px',
      }}
    >
      <DatePicker
        minDate={minStartDate}
        onDateChange={onStartDateChangeWrapped}
        dateLabel={startDateLabel}
        date={startDate}
        dateInput={startDateInput}
        setDateInput={setStartDateInput}
        handleOnBlur={handleOnBlur}
        disabled={disabled}
      />

      <DatePicker
        minDate={startDate}
        onDateChange={onEndDateChange}
        dateLabel={endDateLabel}
        date={endDate}
        dateInput={endDateInput}
        setDateInput={setEndDateInput}
        disabled={disabled}
      />
    </Box>
  );
};

export default Dates;
