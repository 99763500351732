import { ICellEditorParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';
import { DatabaseItemGroup } from '../../../../../shared/types/offers';
import { PRICE_FACTOR } from '../../../../../shared/constants';

// Editor for group row discount
export const discountGroupEditor = (
  props: ICellEditorParams,
  value: number,
  handleMultipleRowValueUpdates?: (
    row: PricingSheetRow[],
    showNotification?: boolean,
  ) => void,
) => {
  const PRICE_MULTIPLIER = 1 + value / 100;
  const newGroupPrice =
    props.node.aggData.proposalPrice * PRICE_MULTIPLIER;
  const percentage = newGroupPrice / props.node.aggData.price;

  const updatedItems = props.node.allLeafChildren.map((item) => {
    let newData = { ...item.data };
    newData.dayPrice *= percentage;
    newData.monthPrice *= percentage;
    return { ...item, data: newData };
  });

  const newDataArray = updatedItems.map((item) => item.data);

  if (handleMultipleRowValueUpdates) {
    handleMultipleRowValueUpdates(newDataArray, false);
  }

  return true;
};

// Editor for group row price
export const priceGroupEditor = (
  props: ICellEditorParams,
  newGroupPrice: number,
  handleMultipleRowValueUpdates?: (
    row: PricingSheetRow[],
    showNotification?: boolean,
  ) => void,
  handleItemGroupUpdate?: (itemGroup: DatabaseItemGroup) => void,
) => {
  const percentage = newGroupPrice / props.node.aggData.price;

  const updatedItems = props.node.allLeafChildren.map((item) => {
    const newData = { ...item.data };
    newData.dayPrice *= percentage;
    newData.monthPrice *= percentage;
    return { ...item, data: newData };
  });

  const newDataArray = updatedItems.map((item) => item.data);

  const firstChildData = props.node.allLeafChildren[0].data;
  if (firstChildData?.itemGroup) {
    if (handleMultipleRowValueUpdates) {
      handleMultipleRowValueUpdates(newDataArray, false);
    }
    if (handleItemGroupUpdate) {
      handleItemGroupUpdate({
        ...firstChildData.itemGroup,
        totalPrice: newGroupPrice * PRICE_FACTOR,
      });
    }
  }

  return true;
};
