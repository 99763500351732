import React, { ReactNode } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ArrowRightAlt, InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import ProductCard from '../../../components/Common/ProductCard';
import { Nullable } from '../../../../../shared/types';
import {
  ImportType,
  ImportChangedRow,
  ImportChangedRowValues,
  ImportTableColumn,
} from '../../../../../shared/types/import';
import TableContainer from '@mui/material/TableContainer';
import { format } from 'date-fns';
import {
  isCriticalEquipmentKey,
  isSurplusEquipmentKey,
} from './helpers';
import Typography from '@mui/material/Typography';

type ExcelImportChangesTableProps = {
  changedRows: ImportChangedRow[];
  columns: ImportTableColumn[];
  importType: ImportType;
};

const hasValue = (value: Nullable<number | string>): boolean =>
  typeof value === 'number' ? !isNaN(value) : Boolean(value);

const getDisplayValue = (
  value: Nullable<number | string>,
  importType: ImportType,
  changedKey: string,
  newRow: boolean = false,
): ReactNode | null => {
  if (
    value === 'undefined' ||
    (value === null && !isCriticalEquipmentKey(changedKey))
  ) {
    return null;
  }
  switch (importType) {
    case ImportType.Common:
      if (changedKey === 'rentalReadyPrice') {
        return `${Number(value)} €`;
      }
      if (changedKey === 'targetUtilRate') {
        return `${Number(value)} %`;
      }
      if (changedKey === 'depreciationPeriod') {
        return `${Number(value)} kk`;
      }
      if (changedKey === 'avgRentalPeriod') {
        return `${Number(value)} pv`;
      }
      if (changedKey === 'requiredRateOfReturn') {
        return `${Number(value)} €`;
      }
      if (changedKey === 'totalBalance') {
        return `${Number(value)}`;
      }
      if (changedKey === 'basicSelectionAmount') {
        return `${Number(value)}`;
      }
      return `${value}`;
    case ImportType.CriticalEquipment:
      if (newRow && !value) {
        return <MissingValue />;
      }
      if (
        changedKey.endsWith('minPriceDay') ||
        changedKey.endsWith('minPriceMonth')
      ) {
        return value ? `${Number(value)} €` : null;
      }
      if (changedKey.endsWith('from') || changedKey.endsWith('to')) {
        return value ? format(new Date(value), 'dd.MM.yyyy') : null;
      }
      return null;
    case ImportType.SurplusEquipment:
      if (newRow && changedKey.endsWith('surplusFrom') && !value) {
        return <MissingValue />;
      }
      if (changedKey.endsWith('changePercentage')) {
        return hasValue(value) ? `${Number(value)} %` : null;
      }
      if (
        changedKey.endsWith('surplusFrom') ||
        changedKey.endsWith('surplusTo')
      ) {
        return value ? format(new Date(value), 'dd.MM.yyyy') : null;
      }
      return null;
    case ImportType.Coefficient:
      return `${Number(value) / 100}`;
    case ImportType.IndustryPercent:
      return `${Number(value) / 100} %`;
  }
};

const ExcelImportChangesTable = ({
  columns = [],
  changedRows,
  importType,
}: ExcelImportChangesTableProps) => {
  return (
    <TableContainer sx={{ maxHeight: 500 }}>
      <Table
        stickyHeader
        sx={{
          border: 'solid #e0e0e0',
          borderWidth: '0 1px 1px',
          borderRadius: '2px',
          '& tr': {
            '& td': {
              borderBottom: 0,
            },
            '&:nth-of-type(even)': {
              backgroundColor: '#fafafa',
            },
          },
          '& th': {
            backgroundColor: '#fafafa',
            borderTop: '1px solid #e0e0e0',
            color: 'primary.main',
            fontWeight: 'bold',
          },
          '& th, & td': {
            fontSize: '0.75rem',
            p: '4px 8px',
            '&:not(:first-of-type)': {
              borderLeft: '1px solid #e0e0e0',
            },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell padding={'none'}>CatClass</TableCell>
            {columns.map(({ key, name }) => {
              const minWidth = key.endsWith('List') ? 100 : 50;
              return (
                <TableCell
                  key={key}
                  sx={{ minWidth }}
                  align={'center'}
                  padding={'none'}
                >
                  {name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {changedRows.map(
            ({ catClass, changes: rowChanges, newRow }) => (
              <TableRow key={catClass}>
                <TableCell padding={'none'}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'grid',
                      gap: 0.25,
                      gridAutoFlow: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    {catClass}
                    <ProductTooltip catClass={catClass} />
                  </Box>
                </TableCell>
                {columns.map(({ key }) => {
                  return (
                    <ImportChangesTableCell
                      key={key}
                      changedKey={key}
                      changes={rowChanges[key]}
                      importType={importType}
                      newRow={newRow}
                    />
                  );
                })}
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type ImportChangesTableCellProps = {
  changedKey: string;
  importType: ImportType;
  changes: ImportChangedRowValues | undefined;
  newRow?: boolean;
};

const ImportChangesTableCell = ({
  changes,
  importType,
  changedKey,
  newRow = false,
}: ImportChangesTableCellProps) => {
  const { oldValue, newValue } = changes ?? {};
  const showChanges =
    (hasValue(oldValue) && hasValue(newValue)) ||
    isCriticalEquipmentKey(changedKey) ||
    isSurplusEquipmentKey(changedKey);
  return (
    <TableCell align={'center'} padding={'none'}>
      {showChanges && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 0.25,
            gridAutoFlow: 'column',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {getDisplayValue(oldValue, importType, changedKey)}{' '}
          {hasValue(oldValue) && (
            <>
              <ArrowRightAlt fontSize={'inherit'} />{' '}
            </>
          )}
          <b>
            {getDisplayValue(
              newValue,
              importType,
              changedKey,
              newRow,
            )}
          </b>
        </Box>
      )}
    </TableCell>
  );
};

const ProductTooltip = styled(
  ({
    catClass,
    className,
    ...props
  }: Omit<TooltipProps, 'children' | 'title'> & {
    catClass: string;
  }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      leaveDelay={100}
      enterDelay={400}
      enterNextDelay={100}
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
      title={<ProductCard catClass={catClass} />}
    >
      <InfoOutlined fontSize={'small'} color={'primary'} />
    </Tooltip>
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '2px solid #e0e0e0',
    height: '500px',
    overflow: 'hidden',
    padding: 0,
    width: '320px',
  },
});

const MissingValue = () => (
  <Typography
    fontWeight={'inherit'}
    fontSize={'inherit'}
    sx={{
      color: '#d32f2f',
    }}
  >
    {'Arvo puuttuu!'}
  </Typography>
);

export default ExcelImportChangesTable;
