import currencyFormatter from '../formatters/priceFormatter';
import NumericEditorV2 from '../Editors/NumericEditorV2';
import {
  getColSpanValue,
  GridColumnProps,
} from '../Columns/ColumnsBase';
import {
  AgGridColumnView,
  CriticalEquipmentPriceKey,
  CriticalEquipmentRowKeys,
  PricingSheetRow,
} from '../../../../../shared/types';
import {
  CellClassParams,
  CellStyle,
  ColDef,
  ColSpanParams,
  ICellEditorParams,
  IDateFilterParams,
  INumberFilterParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import CriticalEquipmentRenderer from '../Renderers/CriticalEquipmentRenderer';
import dateFormatter from '../formatters/dateFormatter';
import DateEditor from '../Editors/DateEditor';
import CriticalEquipmentEditor from '../Editors/CriticalEquipmentEditor';
import { memo } from 'react';
import CriticalEquipmentActionRenderer from '../Renderers/CriticalEquipmentActionRenderer';
import { getDateWithoutTime } from '../../../../../shared/utils/getDateWithoutTime';
import { getCatClassColDef } from '../helpers/getCatClassColDef';
import SwitchEditor from '../Editors/SwitchEditor';

const ACTION_COLUMN_MAX_WIDTH = 60;

const dateFieldFilterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue: string) => {
    if (!cellValue) {
      return 0;
    }

    const cellDate = getDateWithoutTime(cellValue);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'DD.MM.YYYY',
  inRangeInclusive: true,
  minValidYear: 2020,
};

const getNumberFilterDefinition = (
  key: CriticalEquipmentPriceKey,
): ColDef<PricingSheetRow> => ({
  filter: 'agNumberColumnFilter',
  filterParams: {
    numberParser: (value) =>
      value ? parseFloat(value.replace(',', '.')) : null,
  } as INumberFilterParams,
  filterValueGetter: ({ getValue }) => {
    const value = getValue(key);
    return value ? parseFloat(value) : null;
  },
});

const getCriticalEquipmentValue =
  (key: CriticalEquipmentRowKeys) =>
  (
    params:
      | ValueGetterParams<PricingSheetRow>
      | ICellEditorParams<PricingSheetRow>,
  ) =>
    params?.data?.criticalEquipmentItems[0]?.[key] || null;

const hasCriticalEquipment = (data: PricingSheetRow | undefined) =>
  data ? data.criticalEquipmentItems.length > 0 : false;

const getCellStyle = ({
  data,
  node,
}: CellClassParams<PricingSheetRow>): CellStyle => {
  let backgroundColor = 'transparent';
  if (node.uiLevel === 2) {
    backgroundColor = '#f4f4f4';
  }
  if (node.uiLevel === 3) {
    backgroundColor = '#fafafa';
  }
  return hasCriticalEquipment(data)
    ? {
        borderLeft: '1px solid #ccc',
        color: '#000',
        height: 'inherit',
      }
    : {
        backgroundColor,
        borderWidth: 0,
        padding: 0,
      };
};

const AdminColumnsCriticalEquipment = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps) => {
  const { handleUpdateRowValue = () => null } = optionalColumnProps;
  return [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'minPriceDay',
      headerName: 'Minimihinta pv',
      valueGetter: getCriticalEquipmentValue('minPriceDay'),
      valueFormatter: currencyFormatter,
      editable: true,
      cellEditorSelector: ({
        data,
      }: ICellEditorParams<PricingSheetRow>) =>
        hasCriticalEquipment(data)
          ? // use numeric editor to edit existing value
            {
              component: NumericEditorV2,
              params: {
                handleUpdateRowValue,
                allowNegative: false,
                required: true,
              },
            }
          : // use critical equipment editor to create a new item
            {
              component: memo(CriticalEquipmentEditor),
            },
      cellRenderer: memo(CriticalEquipmentRenderer),
      cellRendererParams: {
        actionColumnMaxWidth: ACTION_COLUMN_MAX_WIDTH,
      },
      cellStyle: getCellStyle,
      colSpan: ({ data }: ColSpanParams<PricingSheetRow>) =>
        hasCriticalEquipment(data)
          ? 1
          : getColSpanValue(AgGridColumnView.CRITICAL_EQUIPMENT) - 1,
      // we handle tab, enter and escape behavior in the editor component when creating a new row
      suppressKeyboardEvent: ({
        data,
        editing,
        event,
      }: SuppressKeyboardEventParams<PricingSheetRow>) =>
        !hasCriticalEquipment(data) &&
        editing &&
        (event.code === 'Tab' ||
          event.code === 'Enter' ||
          event.code === 'Escape'),
      ...getNumberFilterDefinition('minPriceDay'),
    },
    {
      field: 'minPriceMonth',
      headerName: 'Minimihinta kk',
      valueGetter: getCriticalEquipmentValue('minPriceMonth'),
      valueFormatter: currencyFormatter,
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
        allowNegative: false,
        required: true,
      },
      cellStyle: getCellStyle,
      ...getNumberFilterDefinition('minPriceMonth'),
    },
    {
      field: 'isRecurring',
      headerName: 'Toistuvuus',
      headerTooltip:
        'Valitse toistetaanko sama ajanjakso joka vuosi. Toistuva ajanjakso päivittyy automaattisesti seuraavalle vuodelle kun syötetty ajanjakso umpeutuu. (esim. 1.10.2024 - 30.3.2025 => 1.10.2025 - 30.3.2026)',
      valueFormatter: (
        params: ValueFormatterParams<PricingSheetRow>,
      ) => {
        return params.value ? 'Toistuu vuosittain' : 'Ei toistu';
      },
      valueGetter: getCriticalEquipmentValue('isRecurring'),
      cellEditor: SwitchEditor,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: getCellStyle,
      editable: true,
      maxWidth: 150,
    },
    {
      field: 'from',
      headerName: 'Alkaa',
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      editable: true,
      valueGetter: getCriticalEquipmentValue('from'),
      cellEditorParams: (
        params: ICellEditorParams<PricingSheetRow>,
      ) => ({
        maxDate: getCriticalEquipmentValue('to')(params),
        handleUpdateRowValue,
      }),
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellStyle: getCellStyle,
    },
    {
      field: 'to',
      headerName: 'Päättyy',
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      editable: true,
      valueGetter: getCriticalEquipmentValue('to'),
      cellEditorParams: (
        params: ICellEditorParams<PricingSheetRow>,
      ) => ({
        minDate: getCriticalEquipmentValue('from')(params),
        handleUpdateRowValue,
      }),
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellStyle: getCellStyle,
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: memo(CriticalEquipmentActionRenderer),
      cellStyle: getCellStyle,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      resizable: false,
      filter: false,
      maxWidth: ACTION_COLUMN_MAX_WIDTH,
    },
  ];
};

export default AdminColumnsCriticalEquipment;
