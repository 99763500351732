import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  SyntheticEvent,
} from 'react';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../AgGrid/styles/styles.scss';
import {
  CellClassParams,
  CellMouseDownEvent,
  ColSpanParams,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridReadyEvent,
  GridState,
  ICellRendererParams,
  IRowNode,
  IsGroupOpenByDefaultParams,
} from 'ag-grid-community';

import {
  PricingSheetRow,
  Nullable,
  TableType,
  PricingBasis,
  AgGridColumnView,
  OfferItem,
} from '../../../../shared/types';

import {
  collapseAllChildren,
  expandAllChildren,
} from '../AgGrid/Listeners/expandCollapseAllChildren';
import { ColDef } from 'ag-grid-community';

import CustomHeader from '../AgGrid/Components/CustomHeader';
import GroupHeaderRenderer from '../AgGrid/Renderers/GroupHeaderRenderer';
import { ExternalFilter } from '../../hooks/useFilters';
import { useImperativeHandle } from 'react';

import AG_GRID_LOCALE_FI from '../AgGrid/locales/locale.fi';
import CatClassRenderer from '../AgGrid/Renderers/CatClassRenderer';
import catClassValueGetter from '../AgGrid/valueGetters/catClassValueGetter';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AgGridRefType } from '../../types';

import { sortByItemType } from '../../../../shared/sortByItemType';
import { useSortedGroupHierarchy } from '../../hooks/useSortedGroupHierarchy';

export type MouseInfoType = {
  mTimer: Nullable<number>;
  overHalfSec?: boolean;
};

export type CustomAgGridMethods = {
  clearAllSelections: () => void;
  selectRowsByCatClass: (catClasses: string[]) => void;
  getSelectedRows: () => PricingSheetRow[];
  getFilteredRows: () => PricingSheetRow[];
  getSelectedNodes: () => IRowNode<PricingSheetRow>[] | undefined;
  gridRef: AgGridRefType | null;
};

interface AgGridProps {
  type: TableType;
  rows: PricingSheetRow[];
  loading: boolean;
  columnDefs: ColDef[] | undefined;
  externalFilters: ExternalFilter[];
  applyActiveFilters?: (row: PricingSheetRow) => boolean;
  getMainMenuItems?: (params: any) => string[];
  disableProviderUpdate?: boolean;
  gridColumnView: AgGridColumnView;
  isSelectable: boolean;
  removeSelectedRow?: (row: PricingSheetRow) => void;
  addSelectedRow?: (row: PricingSheetRow) => void;
  onSelectionChange?: (val: number) => void;
  readOnlyEdit?: boolean;

  groupHeaderInnerRenderer?: (
    row: any,
    rows: PricingSheetRow[],
  ) => JSX.Element;
  catClassInnerRenderer?: (
    row: any,
    rows: PricingSheetRow[],
  ) => JSX.Element;
  customHeaderInnerRenderer?: (
    rows: PricingSheetRow[],
  ) => JSX.Element;
  setSelectedRows?: any;
  pricingBasis?: PricingBasis;
  expandGroups?: boolean;
  // note: causes performance issues when there are a lot of rows
  autoHeight?: boolean;
  fullWidthProductCell?: boolean;
  hideSideBar?: boolean;
  // can be used to force re-rendering of the grid
  gridKey?: string;
  className?: string;
  onFirstDataRendered?: (
    event: FirstDataRenderedEvent<PricingSheetRow>,
  ) => void;
  isGroupOpenByDefault?: (
    params: IsGroupOpenByDefaultParams<PricingSheetRow>,
  ) => boolean;
  onRowSelectedCb?: (event: any) => void;
}

const AgGrid = forwardRef<CustomAgGridMethods, AgGridProps>(
  (
    {
      type,
      rows,
      loading,
      columnDefs,
      externalFilters,
      applyActiveFilters,
      getMainMenuItems,
      disableProviderUpdate,
      gridColumnView,
      isSelectable = false,
      readOnlyEdit = true,
      addSelectedRow,
      removeSelectedRow,
      onSelectionChange,
      groupHeaderInnerRenderer,
      catClassInnerRenderer,
      customHeaderInnerRenderer,
      setSelectedRows,
      pricingBasis,
      expandGroups = false,
      autoHeight = false,
      fullWidthProductCell = false,
      hideSideBar = false,
      gridKey = 'ag-grid',
      className,
      onFirstDataRendered,
      isGroupOpenByDefault,
      onRowSelectedCb,
    },
    ref,
  ) => {
    const gridRef = useRef<AgGridRefType>(null);
    const [initialState, setInitialState] = useState<
      GridState | undefined
    >();
    const [showMachineCardStorage, setShowMachineCardStorage] =
      useLocalStorage('showMachineCard', 'true');

    const showMachineCard = showMachineCardStorage === 'true';
    const setShowMachineCard = (value: boolean) => {
      setShowMachineCardStorage(String(value));
    };
    const { sortGroups } = useSortedGroupHierarchy();

    const [isAllExpanded, setIsAllExpanded] =
      useState<boolean>(false);

    const expandAllListener = useCallback(
      (_event: SyntheticEvent) => {
        gridRef?.current?.api?.expandAll();

        setIsAllExpanded(true);
        gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
      },
      [],
    );

    const collapseAllListener = useCallback(
      (_event: SyntheticEvent) => {
        gridRef?.current?.api?.collapseAll();
        setIsAllExpanded(false);
        gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
      },
      [],
    );

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
      () => ({
        flex: 1,
        initialWidth: 100,
        minWidth: 100,
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressColumnsToolPanel: true,
      }),
      [],
    );
    const autoGroupColumnDef: ColDef<PricingSheetRow> =
      useMemo(() => {
        return {
          // Is the gridColumnView comparisons really needed, or could it be just a simple if else, eithe 0 or 20+?
          colSpan: (params: ColSpanParams<PricingSheetRow>) => {
            if (params?.data?.catClass) {
              return 0;
            }
            if (gridColumnView === AgGridColumnView.PRICING_BOTH) {
              return 12;
            } else if (
              gridColumnView === AgGridColumnView.OFFER_PRICING
            ) {
              // If params has a node it means it is a itemgroup header
              if (params?.node?.group) {
                return 1;
              }
              return 12;
            } else if (
              gridColumnView === AgGridColumnView.INSPECT_BOTH
            ) {
              return 8;
            } else {
              return 15;
            }
          },
          cellStyle: ({ data }: CellClassParams<PricingSheetRow>) =>
            data
              ? {
                  backgroundColor: '#fafafa',
                }
              : null,
          lockPosition: 'left' as ColDef['lockPosition'],
          headerName: 'CatClass',
          field: 'name',
          minWidth: 540,
          filter: 'agTextColumnFilter',
          sort: 'desc',
          comparator: (
            _: string,
            __: string,
            nodeA: IRowNode<OfferItem>,
            nodeB: IRowNode<OfferItem>,
          ) => sortByItemType(nodeA.data, nodeB.data),
          resizable: true,
          headerCheckboxSelection:
            type === TableType.InspectTable ? false : true,
          headerCheckboxSelectionFilteredOnly:
            type === TableType.InspectTable ? false : true,
          valueGetter: catClassValueGetter,
          headerComponent: (params: {
            data: PricingSheetRow;
            displayName: string;
          }) => {
            return (
              <CustomHeader
                displayName={params.displayName}
                expanded={isAllExpanded}
                onCellExpand={
                  isAllExpanded
                    ? collapseAllListener
                    : expandAllListener
                }
                type={type}
                getFilteredRows={getFilteredRows}
                gridColumnView={gridColumnView}
                customHeaderInnerRenderer={customHeaderInnerRenderer}
              />
            );
          },
          cellRendererParams: {
            checkbox: type === TableType.InspectTable ? false : true,
            suppressDoubleClickExpand: true,
            tooltipField: 'catClass',
            innerRenderer: (
              params: ICellRendererParams<PricingSheetRow>,
            ) => {
              if (params?.data?.catClass) {
                return (
                  <CatClassRenderer
                    setShowMachineCard={setShowMachineCard}
                    showMachineCard={showMachineCard}
                    row={params.data}
                    type={type}
                    getFilteredRows={getFilteredRows}
                    catClassInnerRenderer={catClassInnerRenderer}
                    gridColumnView={gridColumnView}
                  />
                );
              }
              return (
                <GroupHeaderRenderer
                  expandAllNodeChildren={expandAllNodeChildren}
                  collapseAllNodeChildren={collapseAllNodeChildren}
                  row={params}
                  groupHeaderInnerRenderer={groupHeaderInnerRenderer}
                  getFilteredRows={getFilteredRows}
                />
              );
            },
          },
          cellClass: `child-header-cell ${
            fullWidthProductCell ? 'full-width' : ''
          }`,
          cellRenderer: 'agGroupCellRenderer',
        };
        /* eslint-disable */
      }, [
        gridColumnView,
        isAllExpanded,
        showMachineCard,
        pricingBasis,
        JSON.stringify(rows),
      ]);
    /* eslint-enable */

    const getSelectedRows = () => {
      const selectedData =
        gridRef?.current?.api?.getSelectedRows() as PricingSheetRow[];
      return selectedData;
    };

    const getSelectedNodes = () => {
      const selectedData = gridRef?.current?.api?.getSelectedNodes();
      return selectedData;
    };

    const getRowId = useCallback(
      (params: GetRowIdParams<PricingSheetRow>) => {
        return 'offerItemId' in params.data
          ? String(params.data.offerItemId)
          : params.data.id >= 0
            ? String(params.data.id)
            : params.data.catClass;
      },
      [],
    );

    const getRowHeight = useCallback((params) => {
      return params.node.group ? 35 : 30;
    }, []);

    const onSelectionChanged = () => {
      if (onSelectionChange) {
        onSelectionChange(getSelectedRows().length);
      }
      const selectedRows = getSelectedRows();

      if (type !== TableType.PreSelectTable) {
        setSelectedRows && setSelectedRows([...selectedRows]);
      }
    };

    const externalFilterChanged = useCallback(() => {
      gridRef?.current?.api?.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback(() => {
      return externalFilters.length > 0;
    }, [externalFilters.length]);

    useEffect(() => {
      externalFilterChanged();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalFilters.length]);

    const doesExternalFilterPass = useCallback(
      (node) => {
        if (!applyActiveFilters) {
          return false;
        }
        return applyActiveFilters(node.data);
      },
      [applyActiveFilters],
    );

    const getFilteredRows = () => {
      let filteredRows: PricingSheetRow[] = [];
      gridRef?.current?.api?.forEachNodeAfterFilter(
        (rowNode: IRowNode) => {
          if (rowNode.data) {
            filteredRows.push(rowNode.data);
          }
        },
      );

      return filteredRows;
    };

    const clearAllSelections = useCallback(() => {
      gridRef?.current?.api?.deselectAll();
    }, [gridRef]);

    const selectRowsByCatClass = useCallback(
      (catClasses: string[]) => {
        gridRef?.current?.api?.forEachNode((node: IRowNode) => {
          if (catClasses.includes(node?.data?.catClass)) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        });
      },
      [gridRef],
    );

    const onGridReady = useCallback(
      (event: GridReadyEvent<PricingSheetRow>) => {
        const gridApi = event.api;
        if (
          type === TableType.PreSelectTable ||
          type === TableType.ProductLists
        ) {
          gridApi.forEachNode((node) => {
            if (node.data?.preSelected) {
              node.setSelected(true);
            }
          });
          if (initialState) {
            const expandedNodes =
              initialState.rowGroupExpansion?.expandedRowGroupIds ||
              [];
            expandedNodes
              .flatMap((id) => gridApi.getRowNode(id) || [])
              .forEach((node) => {
                gridApi.setRowNodeExpanded(node, true, true);
              });
          }
        }
        gridApi.addEventListener(
          'columnVisible',
          function (event: any) {
            const column = event.column;
            const columnName = column.getColId();
            const isVisible = event.visible;
            localStorage.setItem(
              `columnVisibility_${columnName}`,
              isVisible,
            );
          },
        );
      },
      [initialState, type],
    );

    const expandAllNodeChildren = useCallback(
      (event: CellMouseDownEvent) => {
        expandAllChildren(gridRef, event);
      },
      [],
    );

    const collapseAllNodeChildren = useCallback(
      (event: CellMouseDownEvent) => {
        collapseAllChildren(gridRef, event);
      },
      [],
    );

    const onRowSelected = (event: any) => {
      onRowSelectedCb && onRowSelectedCb(event);
      if (event.data && addSelectedRow && removeSelectedRow) {
        if (event.node.isSelected()) {
          addSelectedRow(event.data);
        } else {
          removeSelectedRow(event.data);
        }
      }
    };

    // These methods are available by parent component trough ref
    useImperativeHandle(ref, () => ({
      clearAllSelections() {
        clearAllSelections();
      },
      getSelectedNodes() {
        return getSelectedNodes();
      },
      selectRowsByCatClass(catClasses: string[]) {
        selectRowsByCatClass(catClasses);
      },
      getSelectedRows() {
        return getSelectedRows();
      },
      getFilteredRows() {
        return getFilteredRows();
      },
      gridRef: gridRef.current,
    }));
    return (
      <div>
        <div
          className="ag-theme-material"
          style={{ height: autoHeight ? 'auto' : '90vh' }}
        >
          <AgGridReact
            key={gridKey}
            className={className}
            animateRows={false}
            groupDefaultExpanded={expandGroups ? -1 : 0}
            domLayout={autoHeight ? 'autoHeight' : 'normal'}
            ref={gridRef} // Ref for accessing Grid's API
            initialState={initialState}
            initialGroupOrderComparator={sortGroups}
            rowData={!loading ? rows : undefined} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            localeText={AG_GRID_LOCALE_FI}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection={'multiple'}
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            getRowHeight={getRowHeight}
            getRowId={getRowId} // returns a unique id for each row. https://www.ag-grid.com/react-data-grid/react-hooks/#immutable-data
            onRowSelected={onRowSelected}
            onSelectionChanged={
              disableProviderUpdate ? undefined : onSelectionChanged
            }
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            suppressRowGroupHidesColumns={true}
            tooltipShowDelay={500}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupMaintainOrder={true}
            readOnlyEdit={readOnlyEdit}
            suppressModelUpdateAfterUpdateTransaction={true}
            enableCellChangeFlash={true}
            getMainMenuItems={getMainMenuItems}
            groupAllowUnbalanced={true}
            enableGroupEdit={true}
            onGridPreDestroyed={({ state }) => setInitialState(state)}
            isGroupOpenByDefault={isGroupOpenByDefault}
            {...(!hideSideBar && {
              sideBar: {
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Sarakkeet',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressPivotMode: true,
                      suppressRowGroups: true,
                      suppressValues: true,
                      suppressColumnFilter: true,
                      suppressColumnSelectAll: true,
                      suppressColumnsToolPanel: true,
                    },
                  },
                ],
              },
            })}
          />
        </div>
      </div>
    );
  },
);

export default AgGrid;
