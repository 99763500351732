import { ValueGetterParams } from 'ag-grid-community';
import { OfferItem } from '../../../../../shared/types';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import isGroupHeader from '../../../helpers/isGroupHeader';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';

const getRentalPeriod = (
  leasePeriodStart: Date | undefined,
  leasePeriodEnd: Date | undefined,
) => {
  return leasePeriodStart && leasePeriodEnd
    ? `${formatDateTolocaleDateString(leasePeriodStart)} - ${formatDateTolocaleDateString(leasePeriodEnd)}`
    : '';
};

export const rentalPeriodValueGetter = (
  params: ValueGetterParams<OfferItem>,
) => {
  if (isGroupHeader(params)) {
    const itemGroup = params.node?.allLeafChildren[0].data?.itemGroup;
    if (isSalesItem(itemGroup?.type)) {
      return null;
    } else {
      return getRentalPeriod(
        itemGroup?.leasePeriodStart,
        itemGroup?.leasePeriodEnd,
      );
    }
  }

  const rentalPeriod = getRentalPeriod(
    params.data?.leasePeriodStart,
    params.data?.leasePeriodEnd,
  );
  return !isSalesItem(params.data?.type) ? rentalPeriod : null;
};
