import { ICellEditorParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import constructNewRow from '../helpers/constructNewRow';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { isSameDay } from 'date-fns';
import CellDatePicker, {
  CellDatePickerMinMaxParams,
  CellDatePickerValue,
} from '../../Cells/common/CellDatePicker';
import validateDatePickerValue from '../../../utils/validateDatePickerValue';
import { setDatetoStartOfUTCDay } from '../../../utils/formatDateTimes';

export type DateEditorParams = ICellEditorParams<PricingSheetRow> &
  CellDatePickerMinMaxParams;

interface DateEditorProps extends DateEditorParams {
  handleUpdateRowValue?: (row: PricingSheetRow) => void;
}

const DateEditor = forwardRef((params: DateEditorProps, ref) => {
  const {
    column,
    data,
    minDate,
    maxDate,
    required,
    handleUpdateRowValue = () => null,
  } = params;
  const [value, setValue] = useState<CellDatePickerValue>(
    params.value,
  );
  const field = column.getColId();
  const error = validateDatePickerValue({
    minDate,
    maxDate,
    value,
    required,
  });

  const { setNotification } = useContext(NotificationProvider);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        // don't do anything if the selected date is invalid or wasn't changed
        if (
          !value ||
          Boolean(error) ||
          isSameDay(new Date(params.value), new Date(value))
        ) {
          return;
        }
        const newRow = constructNewRow(
          field,
          data,
          setDatetoStartOfUTCDay(new Date(value)),
        );
        if (newRow) {
          handleUpdateRowValue(newRow);
          return true;
        } else {
          setNotification({
            type: 'SNACKBAR',
            duration: 4000,
            severity: 'error',
            message: 'Virhe päivittäessä uutta arvoa!',
          });
          return false;
        }
      },
      isCancelAfterEnd() {
        return value === null;
      },
    };
  });

  return (
    <CellDatePicker
      autoFocus
      error={error}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={setValue}
      disabled={data.itemGroup != null}
    />
  );
});

export default DateEditor;
