import {
  useState,
  forwardRef,
  memo,
  useImperativeHandle,
  useContext,
} from 'react';
import constructNewRow from '../helpers/constructNewRow';
import { ICellEditorParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';
import { FormControl, MenuItem, Select } from '@mui/material';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';

export type TextEditorParams = ICellEditorParams<PricingSheetRow> & {
  handleUpdateRowValue?: (row: PricingSheetRow) => void;
};

const SwitchEditor = memo(
  forwardRef((params: TextEditorParams, ref) => {
    const {
      column,
      data,
      handleUpdateRowValue = () => null,
    } = params;

    const [value, setValue] = useState<boolean>(
      params.value ?? false,
    );
    const field = column.getColId();
    const { setNotification } = useContext(NotificationProvider);

    const handleChange = (event: any) => {
      const newValue = event.target.value;
      setValue(Boolean(newValue));
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          // update state to context level
          const newRow = constructNewRow(field, data, value);
          handleUpdateRowValue(newRow);

          if (newRow) {
            handleUpdateRowValue(newRow);
            return true;
          } else {
            setNotification({
              type: 'SNACKBAR',
              duration: 4000,
              severity: 'error',
              message: 'Virhe päivittäessä uutta arvoa!',
            });
            return false;
          }
        },
        isCancelAfterEnd() {
          return value === null;
        },
      };
    });

    return (
      <FormControl fullWidth variant="standard" autoFocus>
        <Select size="small" value={value} onChange={handleChange}>
          <MenuItem value={false as any}>Ei toistu</MenuItem>
          <MenuItem value={true as any}>Toistuu vuosittain</MenuItem>
        </Select>
      </FormControl>
    );
  }),
);

export default SwitchEditor;
