import { DesktopDatePicker } from '@mui/x-date-pickers';
import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/system';
import ValidationTooltip from '../../Tooltips/ValidationTooltip';

export type CellDatePickerMinMaxParams = {
  minDate?: CellDatePickerValue;
  maxDate?: CellDatePickerValue;
  required?: boolean;
};

export type CellDatePickerValue = Date | string | null;

export type CellDatePickerParams = CellDatePickerMinMaxParams & {
  autoFocus?: boolean;
  error?: string | null;
  onChange?: (value: CellDatePickerValue) => void;
  value: CellDatePickerValue;
  disabled?: boolean;
};

const CellDatePicker = ({
  autoFocus = false,
  error,
  maxDate,
  minDate,
  onChange = () => null,
  value,
  disabled = false,
}: CellDatePickerParams) => {
  const theme = useTheme();

  const toolTipTitle = disabled
    ? 'Ryhmässä olevan tuotteen vuokra-aika ei ole muokattavissa, muokkaa ryhmän vuokra-aikaa'
    : error ?? '';

  return (
    <DesktopDatePicker
      minDate={minDate ?? undefined}
      maxDate={maxDate ?? undefined}
      value={value}
      onChange={onChange}
      inputFormat="dd.MM.yyyy"
      disabled={disabled}
      mask="__.__.____"
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <Container valid={!error} theme={theme}>
          <ValidationTooltip
            title={toolTipTitle}
            open={disabled || Boolean(error)}
          >
            <Input
              autoFocus={autoFocus}
              ref={inputRef}
              {...inputProps}
              placeholder={'pp.kk.vvvv'}
              disabled={disabled}
            />
          </ValidationTooltip>
          {InputProps?.endAdornment}
        </Container>
      )}
    />
  );
};

const Container = styled.div<{ valid: boolean; theme: Theme }>`
  align-items: center;
  border: ${({ valid, theme }) =>
    valid ? 0 : `${theme.palette.error.main}`};
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 0 11px;
  width: 100%;
`;

const Input = styled.input`
  background-color: transparent;
  border: 0;
  flex: 1;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0;
  width: 100%;
`;

export default CellDatePicker;
