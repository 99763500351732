import { api } from '../index';
import { CreateItemGroupResponseBody } from '../../../shared/types/offers';
import { OfferPricingBasis } from '../../../shared/types';

type ItemGroupBaseProps = {
  groupName: string;
  totalPrice?: number | null;
  quantity: number;
  pricingBasis: OfferPricingBasis;
  showItemsInPdf: boolean;
  leasePeriodStart: Date;
  leasePeriodEnd: Date;
  type: string;
};

export type CreateItemGroupFnProps = ItemGroupBaseProps;
export type UpdateItemGroupFnProps = ItemGroupBaseProps & {
  id: number;
};
export type DeleteItemGroupFnProps = {
  groupId?: number;
};

/**
 * CREATE ITEM GROUP
 */
export const createItemGroup = async (
  groupState: CreateItemGroupFnProps,
): Promise<CreateItemGroupResponseBody> => {
  try {
    const { data } = await api.post<CreateItemGroupResponseBody>(
      '/offers/item-group',
      groupState,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * UPDATE ITEM GROUP
 */
export const updateItemGroup = async (
  groupState: UpdateItemGroupFnProps,
): Promise<Response> => {
  try {
    const { data } = await api.put<Response>(
      `/offers/item-group/${groupState.id}`,
      groupState,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * DELETE ITEM GROUP
 */
export const deleteItemGroup = async (
  groupState: DeleteItemGroupFnProps,
): Promise<Response> => {
  try {
    const { data } = await api.delete<Response>(
      `/offers/item-group/${groupState.groupId}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
