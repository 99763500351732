import React, { useContext } from 'react';
import {
  AgGridColumnView,
  PricingSheetRow,
} from '../../../../../shared/types';
import {
  ImportType,
  ImportTableColumn,
  ImportChangesDataMapping,
} from '../../../../../shared/types/import';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import { ExcelImportDialogCommonProps } from './ExcelImportDialog';
import { HeaderMapperTable } from './ExcelImportHeaderMapper';
import { getUpdatedRows } from './helpers';
import ExcelImportDialogBase from './ExcelImportDialogBase';

const ExcelImportCoefficientDialog = ({
  onClose,
  open,
}: ExcelImportDialogCommonProps) => {
  const { pricingSheetRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);

  const importType = ImportType.Common;

  const tableColumns: ImportTableColumn<keyof PricingSheetRow>[] = [
    { key: 'rentalReadyPrice', name: 'Rental Ready' },
    { key: 'targetUtilRate', name: 'Tavoitekäyttöaste' },
    { key: 'depreciationPeriod', name: 'Poistoaika' },
    { key: 'avgRentalPeriod', name: 'Keskimääräinen vuokra-aika' },
    { key: 'requiredRateOfReturn', name: 'Tuottovaatimus' },
    { key: 'totalBalance', name: 'Kokonaissaldo' },
    { key: 'basicSelectionAmount', name: 'Perusvalikoiman suuruus' },
  ];

  const handleSave = (
    changesDataMapping: ImportChangesDataMapping,
  ) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: getUpdatedRows(pricingSheetRows, changesDataMapping),
    });
    onClose();
  };

  return (
    <ExcelImportDialogBase<AgGridColumnView.ADMIN_COMMON>
      changesTableColumns={tableColumns}
      importType={importType}
      onClose={onClose}
      onSave={handleSave}
      open={open}
      rowData={pricingSheetRows}
      title={'Tuo tiedot Excelistä'}
      renderHeaderMapper={(disabled) => (
        <HeaderMapperTable
          columns={tableColumns}
          disabled={disabled}
          importType={importType}
        />
      )}
    />
  );
};

export default ExcelImportCoefficientDialog;
