import { GridColumnProps } from '../Columns/ColumnsBase';
import {
  PricingSheetRow,
  SurplusEquipmentRowKeys,
} from '../../../../../shared/types';
import {
  ICellEditorParams,
  IDateFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import dateFormatter from '../formatters/dateFormatter';
import DateEditor from '../Editors/DateEditor';
import NumericEditorV2 from '../Editors/NumericEditorV2';
import SurplusEquipmentActionRenderer from '../Renderers/SurplusEquipmentActionRenderer';
import { memo } from 'react';
import { getDateWithoutTime } from '../../../../../shared/utils/getDateWithoutTime';
import { getCatClassColDef } from '../helpers/getCatClassColDef';
import SwitchEditor from '../Editors/SwitchEditor';

const ACTION_COLUMN_MAX_WIDTH = 60;

const cellStyle = {
  borderLeft: '1px solid #ccc',
  color: '#000',
  height: 'inherit',
};

const dateFieldFilterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue: string) => {
    if (!cellValue) {
      return 0;
    }

    const cellDate = getDateWithoutTime(cellValue);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'DD.MM.YYYY',
  inRangeInclusive: true,
  minValidYear: 2020,
};

const getSurplusEquipmentValue =
  (key: SurplusEquipmentRowKeys) =>
  (
    params:
      | ValueGetterParams<PricingSheetRow>
      | ICellEditorParams<PricingSheetRow>,
  ) => {
    if (!params?.data?.surplusEquipmentItem) {
      return;
    }
    if (
      params?.data?.surplusEquipmentItem['surplusFrom'] &&
      !params?.data?.surplusEquipmentItem['changePercentage'] &&
      key !== 'surplusTo'
    ) {
      return params?.data?.surplusEquipmentItem[key] ?? '0';
    }
    return params?.data?.surplusEquipmentItem[key] || null;
  };

const AdminColumnsSurplusEquipment = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps) => {
  const { handleUpdateRowValue = () => null } = optionalColumnProps;
  const columns = [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'changePercentage',
      headerName: 'Muutosprosentti',
      valueGetter: getSurplusEquipmentValue('changePercentage'),
      valueFormatter: (
        params: ValueFormatterParams<PricingSheetRow>,
      ) => {
        return params.value ? `${params.value}%` : '';
      },
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
        allowNegative: true,
        required: false,
      },
    },
    {
      field: 'isRecurringSurplus',
      headerName: 'Toistuvuus',
      headerTooltip:
        'Valitse toistetaanko sama ajanjakso joka vuosi. Toistuva ajanjakso päivittyy automaattisesti seuraavalle vuodelle kun syötetty ajanjakso umpeutuu. (esim. 1.10.2024 - 30.3.2025 => 1.10.2025 - 30.3.2026)',
      valueFormatter: (
        params: ValueFormatterParams<PricingSheetRow>,
      ) => {
        return params.value ? 'Toistuu vuosittain' : 'Ei toistu';
      },
      valueGetter: getSurplusEquipmentValue('isRecurringSurplus'),
      cellEditor: SwitchEditor,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: cellStyle,
      editable: true,
      maxWidth: 150,
    },
    {
      field: 'surplusFrom',
      headerName: 'Alkaa',
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      editable: true,
      valueGetter: getSurplusEquipmentValue('surplusFrom'),
      cellEditorParams: (
        params: ICellEditorParams<PricingSheetRow>,
      ) => ({
        maxDate: getSurplusEquipmentValue('surplusTo')(params),
        handleUpdateRowValue,
      }),
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellStyle: cellStyle,
    },
    {
      field: 'surplusTo',
      headerName: 'Päättyy',
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      editable: true,
      valueGetter: getSurplusEquipmentValue('surplusTo'),
      cellEditorParams: (
        params: ICellEditorParams<PricingSheetRow>,
      ) => ({
        required: false,
        minDate: getSurplusEquipmentValue('surplusFrom')(params),
        handleUpdateRowValue,
      }),
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellStyle: cellStyle,
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: memo(SurplusEquipmentActionRenderer),
      cellStyle: cellStyle,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      resizable: false,
      filter: false,
      maxWidth: ACTION_COLUMN_MAX_WIDTH,
    },
  ];

  return columns;
};

export default AdminColumnsSurplusEquipment;
