import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  OfferItem,
  OfferPricingBasis,
} from '../../../../shared/types';
import {
  GetRowIdParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community';
import discountGetter from '../AgGrid/valueGetters/discountGetter';
import percentFormatter from '../AgGrid/formatters/percentFormatter';
import { Typography } from '@mui/material';
import priceFormatter from '../AgGrid/formatters/priceFormatter';
import { dayPriceGetter } from '../AgGrid/valueGetters/dayPriceGetter';
import { monthPriceGetter } from '../AgGrid/valueGetters/monthPriceGetter';
import { isSalesItem } from '../../../../shared/helpers/isSalesItem';
import ActionButtonRenderer from '../AgGrid/Renderers/ActionButtonRenderer';

const ROUNDING_BASIS = 'TWODECIMALS';

interface GroupingGridProps {
  groupedItems: OfferItem[];
  remainingItems: OfferItem[];
  setGroupedItems: React.Dispatch<React.SetStateAction<OfferItem[]>>;
  pricingBasis: OfferPricingBasis;
  groupName: string;
  groupType: string;
}

const GroupingGrid: React.FC<GroupingGridProps> = ({
  groupedItems,
  remainingItems,
  setGroupedItems,
  pricingBasis,
  groupName,
  groupType,
}) => {
  const selectedGridApi = useRef<any>(null);
  const remainingGridApi = useRef<any>(null);
  const [selectedRows, setSelectedRows] =
    useState<OfferItem[]>(groupedItems);
  const [remainingRows, setRemainingRows] =
    useState<OfferItem[]>(remainingItems);
  const [hasSalesType, setHasSalesType] = useState(
    groupType === 'SALES',
  );

  useEffect(() => {
    setHasSalesType(groupType === 'SALES');
  }, [groupType]);

  useEffect(() => {
    setRemainingRows(remainingItems);
  }, [remainingItems]);

  useEffect(() => {
    setSelectedRows(groupedItems);
  }, [groupType]);

  const columnDefs = [
    {
      headerValueGetter: (params: any) =>
        params.context.actionColumnName,
      cellRenderer: (props: any) => (
        <ActionButtonRenderer
          isGrouped={props.context.isGrouped}
          data={props.data}
          addToGroup={addToGroup}
          removeFromGroup={removeFromGroup}
        />
      ),
      sortable: false,
      maxWidth: 80,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      resizable: false,
      filter: false,
    },
    {
      dndSource: (params: any) => {
        if (selectedRows.length === 0) {
          return true;
        }
        if (hasSalesType) {
          return isSalesItem(params.data.type);
        } else {
          return !isSalesItem(params.data.type);
        }
      },
      field: 'catClass',
      headerName: 'Nimi',
      cellClass: 'full-width',
      valueGetter: (params: any) => {
        return `${params.data.catClass} ${params.data.name}`;
      },
      minWidth: 480,
    },
    {
      field: 'itemGroup.groupName',
      headerName: 'Ryhmä',
      valueGetter: (params: any) => {
        if (!params.data.itemGroup) {
          return 'Ei ryhmää';
        } else {
          return params.data.itemGroup.groupName;
        }
      },
    },
    {
      field: 'quantity',
      headerName: 'Lukumäärä',
    },
    {
      field: 'price',
      headerName: 'Tarjouksen hinta',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return pricingBasis === 'DAY'
          ? dayPriceGetter(params, ROUNDING_BASIS)
          : monthPriceGetter(params, ROUNDING_BASIS);
      },
      valueFormatter: priceFormatter,
    },
    {
      field: 'discount',
      headerName: 'Alennus%',
      valueGetter: discountGetter,
      valueFormatter: percentFormatter,
    },
    {
      field: 'comment',
      headerName: 'Riviselite',
      sortable: false,
    },
  ];

  useEffect(() => {
    setGroupedItems(selectedRows);
  }, [selectedRows, remainingRows, setGroupedItems]);

  const onGridReady = (params: any, gridType: string) => {
    if (gridType === 'selected') {
      selectedGridApi.current = { api: params.api };
    } else if (gridType === 'remaining') {
      remainingGridApi.current = { api: params.api };
    }
  };

  const onDrop = (event: any, gridType: string) => {
    event.preventDefault();
    const draggedRows = JSON.parse(
      event.dataTransfer.getData('text'),
    );

    const containerId = event.currentTarget.id;
    const isDraggingToSelected = containerId === 'selected-container';
    const destinationApi = isDraggingToSelected
      ? selectedGridApi.current?.api
      : remainingGridApi.current?.api;

    if (destinationApi) {
      const addArray = Array.isArray(draggedRows)
        ? draggedRows
        : [draggedRows];

      // Check if the dragged items are already in the destination
      const alreadyInDestination = addArray.every((item) =>
        destinationApi.getRowNode(item.offerItemId),
      );

      if (alreadyInDestination) {
        // If all items are already in the destination, do nothing
        return;
      }

      // Apply transaction to add dragged rows to the destination grid
      destinationApi.applyTransaction({ add: addArray });

      // Remove dragged rows from the source grid/useState
      const sourceApi = isDraggingToSelected
        ? remainingGridApi.current
        : selectedGridApi.current;

      if (sourceApi) {
        sourceApi.api.applyTransaction({ remove: addArray });
        if (isDraggingToSelected) {
          setRemainingRows(
            remainingRows.filter(
              (row) =>
                !addArray.some(
                  (item) => item.offerItemId === row.offerItemId,
                ),
            ),
          );
          setSelectedRows([...selectedRows, ...addArray]);
        } else {
          setSelectedRows(
            selectedRows.filter(
              (row) =>
                !addArray.some(
                  (item) => item.offerItemId === row.offerItemId,
                ),
            ),
          );
          setRemainingRows([...remainingRows, ...addArray]);
        }
      }
    }
  };

  const allowDrop = (event: any) => {
    event.preventDefault();
  };

  const addToGroup = (item: OfferItem) => {
    setRemainingRows(
      remainingRows.filter(
        (row) => row.offerItemId !== item.offerItemId,
      ),
    );
    setSelectedRows([...selectedRows, item]);
  };

  const removeFromGroup = (item: OfferItem) => {
    setSelectedRows(
      selectedRows.filter(
        (row) => row.offerItemId !== item.offerItemId,
      ),
    );
    setRemainingRows([...remainingRows, item]);
  };

  return (
    <div style={{ width: '100%' }}>
      {/* Selected items container */}
      <div
        id="selected-container"
        className="ag-theme-alpine"
        onDragOver={allowDrop}
        onDrop={(event) => onDrop(event, 'selected')}
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          marginBottom: '2rem',
        }}
      >
        <Typography variant="h6" sx={{ mt: 2 }}>
          {/* If there is no groupname, show a no-break space to avoid
          "jump" effect in UI */}
          {groupName || '\u00A0'}
        </Typography>
        <AgGridReact
          domLayout="autoHeight"
          enableRangeSelection={true}
          columnDefs={columnDefs as any}
          context={{
            isGrouped: true,
            actionColumnName: 'Poista',
          }}
          rowData={selectedRows}
          rowDragManaged={true}
          onGridReady={(params) => onGridReady(params, 'selected')}
          getRowId={(params: GetRowIdParams) =>
            params.data.offerItemId
          }
          onDragStarted={(event: any) => {
            event.api.forEachNode((node: RowNode) => {
              event.dataTransfer.setData(
                'text',
                JSON.stringify([node.data]),
              );
            });
          }}
          localeText={{ noRowsToShow: 'Ei näytettäviä rivejä' }}
        />
      </div>
      {/* Remaining items container */}
      <div
        id="remaining-container"
        className="ag-theme-alpine"
        onDragOver={allowDrop}
        onDrop={(event) => onDrop(event, 'remaining')}
        style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        <Typography variant="h6" sx={{ mt: 4 }}>
          Muut tuotteet
        </Typography>
        <AgGridReact
          domLayout="autoHeight"
          enableRangeSelection={true}
          columnDefs={columnDefs as any}
          rowData={remainingRows}
          rowDragManaged={true}
          context={{
            isGrouped: false,
            actionColumnName: 'Lisää',
          }}
          onGridReady={(params) => onGridReady(params, 'remaining')}
          getRowId={(params: GetRowIdParams) =>
            params.data.offerItemId
          }
          onDragStarted={(event: any) => {
            event.api.forEachNode((node: RowNode) => {
              event.dataTransfer.setData(
                'text',
                JSON.stringify([node.data]),
              );
            });
          }}
          localeText={{ noRowsToShow: 'Ei näytettäviä rivejä' }}
        />
      </div>
    </div>
  );
};

export default GroupingGrid;
