import {
  Nullable,
  PricingSheetRow,
} from '../../../../../shared/types';

import { ColDef, RowNode } from 'ag-grid-community';

/*
 * used on both in discount cells and targetUtilRate cell
 * if need to do separate logic, this should be broken into two separate formatters: discountFormatter and targetUtilRateFormatter
 */

const percentFormatter = (params: {
  value: Nullable<number>;
  data: PricingSheetRow;
  colDef: ColDef;
  node: RowNode;
}) => {
  const showByOfferText = params.data?.showByOfferText;
  const field = params.colDef.field;

  if (params.node.group) {
    return params.value + ' %';
  }

  if (
    (field === 'discountDay' && !params.data.proposalDayPrice) ||
    (field === 'discountMonth' && !params.data.proposalMonthPrice)
  ) {
    return '-';
  }

  if (
    showByOfferText &&
    field !== 'dayPrice' &&
    field !== 'monthPrice'
  ) {
    return '';
  }

  return typeof params.value === 'number' ? params.value + ' %' : '';
};

export default percentFormatter;
