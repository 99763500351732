import { memo } from 'react';
import {
  CellClassParams,
  CellStyle,
  EditableCallbackParams,
  ICellEditorParams,
  IDateFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import dateFormatter from '../formatters/dateFormatter';
import { GridColumnProps } from './ColumnsBase';
import DateEditor from '../Editors/DateEditor';
import {
  Language,
  OfferItem,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';
import NumericEditorV2 from '../Editors/NumericEditorV2';
import OfferItemDetailsActionsRenderer from '../Renderers/OfferItemDetailsActionsRenderer';
import { getDateWithoutTime } from '../../../../../shared/utils/getDateWithoutTime';
import {
  OfferCommentRenderer,
  OfferCommentRendererProps,
} from '../Renderers/OfferCommentRenderer';
import { getClassForAgGridCell } from '../../../helpers/cellHelpers';
import { OfferPricingBasisRenderer } from '../Renderers/OfferPricingBasisRenderer';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import { CheckboxRenderer } from '../Renderers/CheckboxRenderer';

interface OptionalColumnProps {
  roundingBasisSheet: RoundingBasis;
  handleUpdateRowValue: (row: PricingSheetRow) => void;
  handleRemoveRow: (row: PricingSheetRow) => void;
  handleCopyRow: (row: PricingSheetRow) => void;
  handleLeaseChange: (row: PricingSheetRow) => void;
  language: Language;
  includeRamiturvaOffer: boolean;
}

const defaultCellStyle: CellStyle = {
  borderLeft: '1px solid #ccc',
  color: '#000',
  display: 'flex',
  height: '100%',
};

const dateFieldFilterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue: string) => {
    if (!cellValue) {
      return 0;
    }

    const cellDate = getDateWithoutTime(cellValue);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'DD.MM.YYYY',
  inRangeInclusive: true,
  minValidYear: 2020,
};

const OfferColumnsItemDetails = ({
  classes,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const {
    handleUpdateRowValue,
    handleRemoveRow,
    handleCopyRow,
    handleLeaseChange,
    includeRamiturvaOffer,
  } = optionalColumnProps;

  return [
    {
      field: 'itemGroup.groupName',
      hide: true,
      rowGroup: true,
      editable: true,
    },
    {
      field: 'pricingBasis',
      headerName: 'Hinnoitteluperuste',
      filter: 'agTextColumnFilter',
      cellRenderer: OfferPricingBasisRenderer,
      cellRendererParams: {
        handleUpdateRowValue,
      },
      valueGetter: ({ data }: ValueGetterParams<OfferItem>) =>
        !isSalesItem(data?.type) ? data?.pricingBasis : null,
      valueFormatter: (params: ValueFormatterParams<OfferItem>) => {
        return params.data?.pricingBasis === 'MONTH'
          ? 'Kuukausihinta'
          : 'Päivähinta';
      },
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'pricingBasis'),
      cellStyle: { ...defaultCellStyle, padding: 0 },
      headerTooltip: 'Onko tuotteella PV vai KK hinta',
    },
    {
      field: 'quantity',
      headerName: 'Lukumäärä',
      editable: true,
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return params.data?.quantity;
      },
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
        allowNegative: false,
        allowZero: false,
        required: false,
      },
      headerTooltip: 'Tuotteiden lukumäärä',
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'quantity'),
      cellStyle: defaultCellStyle,
    },
    {
      field: 'unit',
      headerName: 'Yksikkö',
      filter: 'agTextColumnFilter',
      sortable: false,
      cellRenderer: memo(OfferCommentRenderer),
      cellRendererParams: {
        buttonLabel: null,
      } as OfferCommentRendererProps,
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'comment'),
      cellStyle: {
        borderLeft: '1px solid #ccc',
        display: 'flex',
        padding: 0,
      } as CellStyle,
      headerTooltip: 'Vapaamuotoinen kommentti',
    },
    {
      field: 'leasePeriodStart',
      headerName: 'Alkamisaika',
      editable: ({ data }: EditableCallbackParams<OfferItem>) =>
        !isSalesItem(data?.type),
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        const { leasePeriodStart, type } = params.data || {};
        return !isSalesItem(type) ? leasePeriodStart : null;
      },
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellEditorParams: (params: ICellEditorParams<OfferItem>) => ({
        params,
        handleUpdateRowValue: handleLeaseChange,
        maxDate: params.data?.leasePeriodEnd,
      }),
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(
          params.data,
          classes,
          'leasePeriodStart',
        ),
      cellStyle: defaultCellStyle,
      headerTooltip: 'Tuotteen vuokra-ajan alkamisaika',
    },
    {
      field: 'leasePeriodEnd',
      headerName: 'Päättymisaika',
      filter: 'agDateColumnFilter',
      filterParams: dateFieldFilterParams,
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        const { leasePeriodEnd, type } = params.data || {};
        return !isSalesItem(type) ? leasePeriodEnd : null;
      },
      editable: ({ data }: EditableCallbackParams<OfferItem>) =>
        !isSalesItem(data?.type),
      valueFormatter: dateFormatter,
      cellEditor: DateEditor,
      cellEditorParams: (params: ICellEditorParams<OfferItem>) => ({
        params,
        handleUpdateRowValue: handleLeaseChange,
        minDate: params.data?.leasePeriodStart,
      }),
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'leasePeriodEnd'),
      cellStyle: defaultCellStyle,
      headerTooltip: 'Tuotteen vuokra-ajan päättymisaika',
    },
    {
      field: 'comment',
      headerName: 'Riviselite',
      filter: 'agTextColumnFilter',
      sortable: false,
      cellRenderer: memo(OfferCommentRenderer),
      cellRendererParams: {
        buttonLabel: null,
      } as OfferCommentRendererProps,
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'comment'),
      cellStyle: {
        borderLeft: '1px solid #ccc',
        display: 'flex',
        padding: 0,
      } as CellStyle,
      headerTooltip: 'Vapaamuotoinen kommentti',
    },
    {
      field: 'includeRamiturva',
      headerName: 'RamiTurva',
      sortable: true,
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        handleUpdateRowValue,
        includeRamiturvaOffer,
      },
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return params.data?.includeRamiturva;
      },
      cellStyle: {
        ...defaultCellStyle,
        backgroundColor: '#fff',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 70,

      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      resizable: false,
      filter: false,
    },
    {
      field: 'Actions',
      headerName: 'Toiminnot',
      sortable: false,
      cellRenderer: memo(OfferItemDetailsActionsRenderer),
      cellRendererParams: {
        handleRemoveRow,
        handleCopyRow,
      },
      cellStyle: {
        ...defaultCellStyle,
        backgroundColor: '#fff',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 100,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      resizable: false,
      filter: false,
    },
  ];
};

export default OfferColumnsItemDetails;
