import {
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import OfferStepActionContainer from './OfferStepActionContainer';
import { PictureAsPdf, WarningAmber } from '@mui/icons-material';
import {
  CompareType,
  DatabaseOfferItem,
  DatabaseOfferWithItems,
  OfferCompareState,
} from '../../../../shared/types/offers';
import { useItems } from '../../hooks/useItems';
import {
  AgGridColumnView,
  OfferItem,
  PricingBasis,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';
import { useColumns } from '../../hooks/useColumns';
import { useFilters } from '../../hooks/useFilters';
import { useMutation } from '@tanstack/react-query';
import { useOfferStore } from '../../stores/offerStore';
import AgGrid, {
  CustomAgGridMethods,
} from '../../components/PricingTable/AgGrid';
import EditPricesDialog from '../../components/PricingTable/EditPricesDialog';
import CopyPricesDialog from '../../components/PricingTable/CopyPricesDialog';
import RemoveItemsDialog from '../../components/PricingTable/RemoveItemsDialog';
import { ResetFiltersButton } from '../../components/PricingTable/ResetFiltersButton';
import unionBy from 'lodash/unionBy';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import PricingFilters from '../../components/PricingTable/PricingFilters';
import {
  catClassInnerRenderer,
  getUpdatedOfferItems,
  getWarningCounts,
  NotificationText,
  ROUNDING_BASIS,
} from './helpers';
import SearchExternalSheet from '../../components/PricingTable/SearchExternalSheet';
import { getOfferExternalComparisonPriceValue } from '../../helpers/cellHelpers';
import { SheetComment } from '../../components/Common/SheetComment';
import { OfferStepTitle } from './OfferStepTitle';
import { OfferSummaryWarnings } from './OfferSummaryWarnings';
import { useUpdateOfferItem } from '../../hooks/useUpdateOfferItem';
import { useUpdateOfferItems } from '../../hooks/useUpdateOfferItems';
import { useDeleteOfferItems } from '../../hooks/useDeleteOfferItems';
import { useOfferPdf } from '../../hooks/useOfferPdf';
import { useUpdateSalesItemPricing } from '../../hooks/useUpdateSalesItemPricing';
import { sortByItemType } from '../../../../shared/sortByItemType';
import NewGroupDialog from '../../components/PricingTable/NewGroupDialog';
import EditGroupDialog from '../../components/PricingTable/EditGroupDialog';
import {
  UpdateItemGroupFnProps,
  updateItemGroup,
} from '../../services/itemGroups';

type OfferPricingProps = {
  offerSheet: DatabaseOfferWithItems;
  renderStepActions: () => ReactNode;
};

const offerColumnView = AgGridColumnView.OFFER_PRICING;

const compareExternal = [
  {
    type: 'contract',
    label: 'Sopimushinnasto',
    placeholder: 'Valitse asiakkaan sopimushinnasto',
  },
  {
    type: 'comparison',
    label: 'Vertailuhinnasto',
    placeholder: 'Valitse vertailuhinnasto',
  },
] as const;

const copyProposalPrices = <T extends PricingSheetRow>(
  item: T,
  copyColumns: PricingBasis,
): T => {
  const dayPrice =
    (copyColumns === 'DAY' || copyColumns === 'BOTH') &&
    item.proposalDayPrice
      ? item.proposalDayPrice
      : item.dayPrice;
  const monthPrice =
    (copyColumns === 'MONTH' || copyColumns === 'BOTH') &&
    item.proposalMonthPrice
      ? item.proposalMonthPrice
      : item.monthPrice;
  return {
    ...item,
    dayPrice,
    monthPrice,
  };
};

const copyExternalPrices = <T extends PricingSheetRow>(
  item: T,
  copyColumns: PricingBasis,
  type: CompareType,
): T => {
  const externalDayPrice = getOfferExternalComparisonPriceValue(
    item,
    type,
    'DAY',
  );
  const externalMonthPrice = getOfferExternalComparisonPriceValue(
    item,
    type,
    'MONTH',
  );
  const dayPrice =
    (copyColumns === 'DAY' || copyColumns === 'BOTH') &&
    externalDayPrice
      ? externalDayPrice
      : item.dayPrice;
  const monthPrice =
    (copyColumns === 'MONTH' || copyColumns === 'BOTH') &&
    externalMonthPrice
      ? externalMonthPrice
      : item.monthPrice;
  return {
    ...item,
    dayPrice,
    monthPrice,
  };
};

export const OfferPricing = ({
  offerSheet,
  renderStepActions = () => null,
}: OfferPricingProps) => {
  const { approvalApproverComment, id, name, pricingBasis } =
    offerSheet;
  const [compare, setCompareState] = useOfferStore((state) => [
    state.compare,
    state.setCompareState,
  ]);

  // grid ref
  const agGridRef = useRef<CustomAgGridMethods>(null);
  // store offer sheet items in a ref to be used by handleUpdateRowValue
  const offerSheetItemsRef = useRef<OfferItem[]>([]);
  const offerSheetItemsDatabaseRef = useRef<DatabaseOfferItem[]>([]);

  // get offer sheet item data
  const { data: offerSheetItems = [], isFetching: isLoadingItems } =
    useItems({
      includeHidden: true,
      offerSheetItemsOnly: true,
      offerSheet,
    });
  offerSheetItemsRef.current = offerSheetItems;
  offerSheetItemsRef.current.sort(sortByItemType).reverse();
  offerSheetItemsDatabaseRef.current = offerSheet.items;

  const { setNotification } = useContext(NotificationProvider);
  const isLoadingPricing = useUpdateSalesItemPricing(offerSheet);

  const isLoadingItemData = isLoadingItems || isLoadingPricing;
  // download pdf query
  const { refetch: downloadPdf, isFetching: isFetchingPdf } =
    useOfferPdf({
      id: String(id),
      draft: true,
      pdfName: name,
    });

  // delete offer sheet item(s) mutation
  const { mutate: deleteOfferItems } = useDeleteOfferItems({
    offerSheetId: id,
  });

  // Update itemGroup mutation
  const { mutate: updateItemGroupMutation } = useMutation({
    mutationFn: updateItemGroup,
    onSuccess: () => {
      if (updateItems) {
        handleMultipleRowValueUpdates(
          offerSheetItemsRef.current,
          false,
        );
      }
      setTimeout(() => {
        agGridRef.current?.gridRef?.api.refreshClientSideRowModel(
          'group',
        );
      });
    },
  });
  // update offer sheet -ITEMS- mutation
  const {
    mutate: updateOfferItems,
    isPending: isUpdatingOfferItems,
  } = useUpdateOfferItems();
  // update offer sheet -ITEM- mutation
  const { mutate: updateOfferItem, isPending: isUpdatingOfferItem } =
    useUpdateOfferItem({
      onSuccess: (data) => {
        const updatedItemIndex = offerSheetItems.findIndex(
          (item) => item.offerItemId === data.id,
        );
        // the catClass column cell renderer needs to be refreshed manually as it won't update automatically
        const gridApi = agGridRef.current?.gridRef?.api;
        if (gridApi && updatedItemIndex !== -1) {
          const rowNode =
            gridApi.getDisplayedRowAtIndex(updatedItemIndex);
          setTimeout(() => {
            gridApi.refreshCells({
              force: true,
              suppressFlash: true,
              columns: ['catClass'],
              rowNodes: rowNode ? [rowNode] : [],
            });
          });
        }
        setTimeout(() => {
          agGridRef.current?.gridRef?.api.refreshClientSideRowModel(
            'aggregate',
          );
        });
      },
    });
  const isUpdatingItemData =
    isUpdatingOfferItem || isUpdatingOfferItems;

  const [selectedRows, setSelectedRows] = useState<OfferItem[]>([]);
  const [updateItems, setUpdateItems] = useState<boolean>(false);

  const {
    activeFilters,
    addFilter,
    toggleActiveFilter,
    isChecked,
    clearFilters,
    applyActiveFilters,
  } = useFilters();

  const handleItemGroupUpdate = (
    itemGroup: UpdateItemGroupFnProps,
    updateItems: boolean = false,
  ) => {
    setUpdateItems(updateItems);
    updateItemGroupMutation(itemGroup);
  };

  const handleUpdateRowValue = ({
    dayPrice,
    monthPrice,
    offerItemId,
    pricingBasis,
    quantity,
    unit,
    ramiturvaDayPrice,
    ramiturvaMonthPrice,
    ramiturvaName,
  }: OfferItem) => {
    updateOfferItem({
      id: String(offerItemId),
      item: {
        dayPrice,
        monthPrice,
        pricingBasis,
        quantity,
        unit,
        ramiturvaDayPrice,
        ramiturvaMonthPrice,
        ramiturvaName,
      },
    });
  };

  const handleMultipleRowValueUpdates = (
    updatedRows: OfferItem[],
    showNotification: boolean = true,
  ) => {
    const updatedItems = unionBy(
      updatedRows,
      offerSheetItems,
      'offerItemId',
    );
    const updatedItemIds = updatedRows.map(({ id }) => id);
    // This was added for sales and service items, which might not have ids
    const updatedOfferItemId = updatedRows.map(
      ({ offerItemId }) => offerItemId,
    );
    const updatedDatabaseOfferItems =
      offerSheetItemsDatabaseRef.current.filter(
        ({ itemId, id }) =>
          (itemId && updatedItemIds.includes(itemId)) ||
          (!itemId && updatedOfferItemId.includes(id)),
      );
    updateOfferItems(
      {
        id: String(id),
        items: getUpdatedOfferItems(
          updatedDatabaseOfferItems,
          updatedItems,
        ),
      },
      {
        onSuccess: () => {
          if (showNotification) {
            setNotification({
              type: 'SNACKBAR',
              duration: 3000,
              severity: 'success',
              message: 'Tuotteiden hinnat päivitetty!',
            });
          }
          // This was added so the pricingBasis on new group items
          // is updated correctly
          agGridRef.current?.gridRef?.api.redrawRows();
          setTimeout(() => {
            agGridRef.current?.gridRef?.api.refreshClientSideRowModel(
              'group',
            );
          });
          agGridRef.current?.clearAllSelections(); // grid
        },
      },
    );
  };

  const handleCopyPrices =
    (field: 'proposal' | CompareType) =>
    (selectedRows: OfferItem[], copyColumns: PricingBasis) => {
      const selectedOfferItemIds = selectedRows.map(
        (row) => row.offerItemId,
      );
      const updatedItems = offerSheetItems.flatMap((item) => {
        if (selectedOfferItemIds.includes(item.offerItemId)) {
          return field === 'proposal'
            ? copyProposalPrices(item, copyColumns)
            : copyExternalPrices(item, copyColumns, field);
        } else {
          return [];
        }
      });
      const selectedDatabaseOfferItems = offerSheet.items.filter(
        ({ id }) => selectedOfferItemIds.includes(id),
      );
      updateOfferItems({
        id: String(id),
        items: getUpdatedOfferItems(
          selectedDatabaseOfferItems,
          updatedItems,
        ),
      });
      setSelectedRows([]); // provider
      agGridRef.current?.gridRef?.api.refreshClientSideRowModel(
        'group',
      );
      agGridRef.current?.clearAllSelections(); // grid
    };

  const { columnDefs, gridColumnView } = useColumns(offerColumnView, {
    roundingBasisSheet: ROUNDING_BASIS,
    handleUpdateRowValue,
    handleMultipleRowValueUpdates,
    handleItemGroupUpdate,
  });
  const warningCounts = useMemo(
    () => getWarningCounts(offerSheetItems),
    [offerSheetItems],
  );

  const removeRows = () => {
    const removedIds = selectedRows.flatMap(
      ({ offerItemId }) => offerItemId || [],
    );
    deleteOfferItems({ id: removedIds });
    setNotification({
      type: 'SNACKBAR',
      severity: 'success',
      message: `Poistettu ${selectedRows.length} ${
        selectedRows.length === 1 ? 'tuote' : 'tuotetta'
      } hinnoittelusta`,
      duration: 3000,
    });
  };

  return (
    <>
      <OfferStepActionContainer>
        {renderStepActions()}
      </OfferStepActionContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <OfferStepTitle
          title={'Hinnoittelu'}
          offerSheet={offerSheet}
          sx={{
            my: 0,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          {approvalApproverComment && (
            <SheetComment
              severity={'warning'}
              title={'Palauttajan perustelut:'}
              comment={approvalApproverComment}
              sx={{
                alignSelf: 'start',
              }}
            />
          )}
          <OfferSummaryWarnings
            dense
            loading={isLoadingItems}
            warningCounts={warningCounts}
            width={500}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'start',
          display: 'flex',
          gap: 1,
          my: 2,
        }}
      >
        {/* COMPARE EXTERNAL */}
        {compareExternal.map((item) => {
          const type: keyof OfferCompareState = `${item.type}Sheet`;
          return (
            <SearchExternalSheet
              key={type}
              label={item.label}
              externalPricingSheetId={compare[type].id}
              miraSheetIdDefault={String(compare[type].id)}
              externalSheetNameDefault={compare[type].name}
              selectedOptionDefault={compare[type].selectedOption}
              setExternalPricingSheetId={(value) =>
                setCompareState(type, { id: value })
              }
              handleClearComparison={() => {
                setCompareState(type, null);
              }}
              handleClear={() => {
                setCompareState(type, null);
              }}
              handleExternalItemsResponse={(items) => {
                setCompareState(type, { items });
              }}
              handleExternalPricingSheetResponse={({ sheetName }) => {
                setCompareState(type, { name: sheetName });
              }}
              onSelectionChange={(selectedOption) => {
                setCompareState(type, { selectedOption });
              }}
              tooltipTitle="Lataa hinnasto vertailuun MIRA:sta. Vertailuhinnat latautuvat taulukon VRT-sarakkeisiin"
              selectPlaceholder={item.placeholder}
            />
          );
        })}
        <Button
          sx={{ ml: 'auto' }}
          startIcon={<PictureAsPdf />}
          variant={'outlined'}
          onClick={() => downloadPdf()}
          disabled={
            isLoadingItemData || isUpdatingItemData || isFetchingPdf
          }
          endIcon={
            isFetchingPdf && (
              <CircularProgress size={16} thickness={4} />
            )
          }
        >
          Lataa luonnos
        </Button>
      </Box>
      <PricingFilters
        addFilter={addFilter}
        pricingBasis={pricingBasis}
        toggleActiveFilter={toggleActiveFilter}
        isChecked={isChecked}
        showZeroPriceFilter={true}
        clearFilters={clearFilters}
        showCriticalPriceFilter={true}
        showCoefficientFilters={true}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '0 1rem',
          mt: 1,
          mb: 1,
        }}
      >
        <Typography color={'text.primary'}>
          Hinnoiteltavia tuotteita yhteensä{' '}
          <b>{offerSheetItems.length}</b>, valittu{' '}
          <b>{selectedRows.length}</b>
        </Typography>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexShrink: 0,
            gap: 1,
          }}
        >
          <EditPricesDialog
            onChangePrices={handleMultipleRowValueUpdates}
            backgroundInfo={offerSheet}
            selectedRows={selectedRows}
          />
          <CopyPricesDialog
            enabledSources={['PROPOSAL', 'COMPARISON', 'CONTRACT']}
            onCopyPricesComparison={handleCopyPrices('comparison')}
            onCopyPricesProposal={handleCopyPrices('proposal')}
            onCopyPricesContract={handleCopyPrices('contract')}
            selectedRows={selectedRows}
            contractSheetName={compare.contractSheet.name}
            comparisonSheetName={compare.comparisonSheet.name}
          />
          <RemoveItemsDialog
            selectedRows={selectedRows}
            onRemoveRows={removeRows}
            dialogText={'Tarjouksen tuotteet'}
          />
          <ResetFiltersButton
            gridApi={agGridRef.current?.gridRef?.api}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ my: '18px' }}
        >
          <NewGroupDialog
            items={offerSheetItemsRef.current}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleMultipleRowValueUpdates={
              handleMultipleRowValueUpdates
            }
            leasePeriodStart={
              new Date(offerSheet.offerLeasePeriodStart)
            }
            leasePeriodEnd={new Date(offerSheet.offerLeasePeriodEnd)}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ my: '18px' }}
        >
          <EditGroupDialog
            items={offerSheetItemsRef.current}
            selectedRows={selectedRows}
            handleMultipleRowValueUpdates={
              handleMultipleRowValueUpdates
            }
            groupId={selectedRows[0]?.itemGroup?.id}
            leasePeriodStart={selectedRows[0]?.leasePeriodStart}
            leasePeriodEnd={selectedRows[0]?.leasePeriodEnd}
          />
        </Box>
      </Box>
      {isLoadingItemData && (
        <NotificationText>
          Ladataan tuotteita
          <CircularProgress size={20} sx={{ ml: 1 }} thickness={5} />
        </NotificationText>
      )}
      {!isLoadingItemData && offerSheetItems.length === 0 && (
        <NotificationText>
          <WarningAmber />
          Tuotteita ei löytynyt!
        </NotificationText>
      )}
      {!isLoadingItemData && offerSheetItems.length > 0 && (
        <AgGrid
          autoHeight
          fullWidthProductCell
          ref={agGridRef}
          pricingBasis={pricingBasis}
          isSelectable={true}
          gridColumnView={gridColumnView}
          type={TableType.OfferPricingTable}
          rows={offerSheetItems}
          loading={false}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs ?? []}
          externalFilters={activeFilters}
          applyActiveFilters={applyActiveFilters}
          catClassInnerRenderer={catClassInnerRenderer}
          expandGroups={true}
        />
      )}
    </>
  );
};
