import { hasRamiturva } from '../../../../../shared/helpers/hasRamiturva';
import { Nullable, OfferItem } from '../../../../../shared/types';

import { ColDef, RowNode } from 'ag-grid-community';

const ramiturvaPriceFormatter = (params: {
  value: Nullable<number>;
  data: OfferItem;
  colDef: ColDef;
  node: RowNode;
}) => {
  if (params.node.group) {
    return params.value + ' €';
  }

  if (!hasRamiturva(params.data)) {
    return '';
  }

  if (!params.value) {
    return '0 €';
  }

  return params.value + ' €';
};

export default ramiturvaPriceFormatter;
