import {
  ICellEditorParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { OfferItem } from '../../../shared/types';

const isGroupHeader = (
  params: ICellEditorParams | ValueGetterParams<OfferItem>,
) => {
  return params.node?.group;
};

export default isGroupHeader;
