import ValidationTooltip from '../../Tooltips/ValidationTooltip';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import React from 'react';
import { HandleDateChange } from '../InitialInformationStep';

type DatePickerParams = {
  date: Date;
  onDateChange: HandleDateChange;
  minDate?: Date;
  dateLabel: string;
  dateInput: Date | null;
  setDateInput: React.Dispatch<React.SetStateAction<Date | null>>;
  handleOnBlur?: (date: Date) => void;
  disabled?: boolean;
};

export const DatePicker = ({
  minDate,
  onDateChange,
  dateLabel,
  dateInput,
  setDateInput,
  handleOnBlur,
  disabled = false,
}: DatePickerParams) => {
  const validateDatePickerValue = (value: Date | null) => {
    if (
      value === null ||
      (value instanceof Date && value.toString() === 'Invalid Date')
    ) {
      return 'Tarkista päivämäärä';
    }
    return null;
  };

  /**
   * @param value String must be in format of dd.mm.yyyy
   * @returns Date
   */
  const createDateFromString = (value: string) => {
    const [day, month, year] = value.split('.');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const error = validateDatePickerValue(dateInput);

  return (
    <ValidationTooltip title={error || ''} open={Boolean(error)}>
      <DesktopDatePicker
        minDate={minDate ?? undefined}
        value={dateInput}
        onChange={setDateInput}
        onAccept={onDateChange}
        label={dateLabel}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={(val) => {
              const inputDate = createDateFromString(
                String(val.target.value),
              );

              if (!Boolean(validateDatePickerValue(inputDate))) {
                onDateChange(inputDate);
                handleOnBlur && handleOnBlur(inputDate);
              }
            }}
            fullWidth
          />
        )}
      />
    </ValidationTooltip>
  );
};
