import { ValueGetterParams } from 'ag-grid-community';
import { OfferItem } from '../../../../../shared/types';
import isGroupHeader from '../../../helpers/isGroupHeader';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import { DatabaseItemGroup } from '../../../../../shared/types/offers';

const getPricingBasis = (
  item: OfferItem | DatabaseItemGroup | undefined,
) => {
  return !isSalesItem(item?.type) ? item?.pricingBasis : null;
};

export const pricingBasisValueGetter = (
  params: ValueGetterParams<OfferItem>,
) => {
  if (isGroupHeader(params)) {
    const itemGroup = params.node?.allLeafChildren[0].data?.itemGroup;
    return getPricingBasis(itemGroup);
  }
  return getPricingBasis(params.data);
};
