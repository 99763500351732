import BooleanRenderer from '../Renderers/BooleanRenderer';
import BelongsToPreSelectionRenderer from '../Renderers/BelongsToPreSelectionRenderer';

import {
  PreSelectionType,
  PricingSheetRow,
} from '../../../../../shared/types';
import removalDayPriceFormatter from '../formatters/removalDayPriceFormatter';
import removalMonthPriceFormatter from '../formatters/removalMonthPriceFormatter';
import percentFormatter from '../formatters/percentFormatter';
import currencyFormatter from '../formatters/priceFormatter';
import depreciationPeriodFormatter from '../formatters/depreciationPeriodFormatter';

import ItemStatusRenderer from '../Renderers/ItemStatusRenderer';
import { memo } from 'react';

import NumericEditorV2 from '../Editors/NumericEditorV2';

import { GridColumnProps } from '../Columns/ColumnsBase';
import { ValueFormatterParams } from 'ag-grid-community';
import MultiLineHeader from '../Components/MultiLineHeader';
import { getCatClassColDef } from '../helpers/getCatClassColDef';
import requiredRateOfReturnGetter from '../valueGetters/requiredRateOfReturnGetter';

interface OptionalColumnProps {
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const AdminColumnsCommon = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;

  return [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'status',
      headerName: 'Status',
      filter: false,
      cellRenderer: memo(ItemStatusRenderer),
      cellStyle: {
        borderLeft: '1px solid #ccc',
      },
      maxWidth: 80,
    },
    {
      field: 'rentalReadyPrice',
      headerName: 'Rental Ready',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyFormatter,
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'targetUtilRate',
      headerName: 'Tavoitekäyttöaste',
      filter: 'agNumberColumnFilter',
      valueFormatter: percentFormatter,
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'avgRentalPeriod',
      headerName: 'Keskimääräinen vuokra-aika',
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Keskimääräinen',
        header2: 'vuokra-aika',
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? `${params.value} pv` : '';
      },
      filter: 'agNumberColumnFilter',
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'depreciationPeriod',
      headerName: 'Poistoaika',
      valueFormatter: depreciationPeriodFormatter,
      filter: 'agNumberColumnFilter',
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'requiredRateOfReturn',
      headerName: 'Tuottovaatimus vuodessa',
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Tuottovaatimus',
        header2: 'vuodessa',
      },
      valueGetter: (params: { data: PricingSheetRow }) =>
        requiredRateOfReturnGetter(params, 'requiredRateOfReturn'),
      filter: 'agNumberColumnFilter',
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'totalBalance',
      headerName: 'Kokonaissaldo',
      filter: 'agNumberColumnFilter',
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'basicSelectionAmount',
      headerName: 'Perusvalikoiman suuruus',
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Perusvalikoiman',
        header2: 'suuruus',
      },
      valueGetter: (params: { data: PricingSheetRow }) =>
        requiredRateOfReturnGetter(params, 'basicSelectionAmount'),
      filter: 'agNumberColumnFilter',
      editable: true,
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'includeInPricing',
      headerName: 'Hinnoitellaan',
      filter: false, // TODO: implement boolean floating filter
      cellRenderer: memo(BooleanRenderer),
      cellRendererParams: {
        column: 'includeInPricing',
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 120,
    },
    {
      field: 'markedAsRemoved',
      headerName: 'Poistunut',
      filter: false, // TODO: implement boolean floating filter
      cellRenderer: memo(BooleanRenderer),
      cellRendererParams: {
        column: 'markedAsRemoved',
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 120,
    },
    {
      field: 'isRamigreen',
      headerName: 'Ramigreen',
      filter: false, // TODO: implement boolean floating filter
      cellRenderer: memo(BooleanRenderer),
      cellRendererParams: {
        column: 'isRamigreen',
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 120,
    },
    {
      field: 'removalPriceDay',
      headerName: 'Poistokustannushinta pv',
      filter: 'agNumberColumnFilter',
      valueFormatter: removalDayPriceFormatter,
      cellStyle: {
        backgroundColor: '#fafafa',
      },
    },
    {
      field: 'removalPriceMonth',
      headerName: 'Poistokustannushinta kk',
      filter: 'agNumberColumnFilter',
      valueFormatter: removalMonthPriceFormatter,
      cellStyle: {
        backgroundColor: '#fafafa',
      },
    },
    {
      field: 'belongsToCompactPreSelection',
      headerName: 'Suppea esivalinta',
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Suppea',
        header2: 'esivalinta',
      },
      filter: false, // TODO: implement boolean floating filter
      cellRenderer: memo(BelongsToPreSelectionRenderer),
      cellRendererParams: {
        type: PreSelectionType.COMPACT,
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
      },
      maxWidth: 120,
    },
    {
      field: 'belongsToLargePreSelection',
      headerName: 'Laaja esivalinta',
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Laaja',
        header2: 'esivalinta',
      },
      filter: false, // TODO: implement boolean floating filter
      cellRenderer: memo(BelongsToPreSelectionRenderer),
      cellRendererParams: {
        type: PreSelectionType.LARGE,
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
      },
      maxWidth: 120,
    },
  ];
};

export default AdminColumnsCommon;
