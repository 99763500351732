import React, { ChangeEvent } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

interface GroupDialogRadioButtonsOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface GroupDialogRadioButtonsProps {
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: GroupDialogRadioButtonsOption[];
  disabled?: boolean;
}

const GroupDialogRadioButtons: React.FC<
  GroupDialogRadioButtonsProps
> = ({ label, value, onChange, options, disabled = false }) => (
  <Box>
    <FormControl>
      <Grid container alignItems="center">
        <Grid item>
          <FormLabel focused={true}>{label}</FormLabel>
        </Grid>
        <Grid item style={{ marginLeft: '10px' }}>
          <RadioGroup row value={value} onChange={onChange}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={disabled || option.disabled}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  </Box>
);

export default GroupDialogRadioButtons;
