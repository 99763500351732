import React from 'react';
import { Add, Remove } from '@mui/icons-material';

interface ActionButtonRendererProps {
  isGrouped: boolean;
  data: any;
  addToGroup: (item: any) => void;
  removeFromGroup: (item: any) => void;
}

const ActionButtonRenderer: React.FC<ActionButtonRendererProps> = ({
  isGrouped,
  data,
  addToGroup,
  removeFromGroup,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {isGrouped ? (
        <Remove
          color="error"
          onClick={() => removeFromGroup(data)}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <Add
          color="primary"
          onClick={() => addToGroup(data)}
          style={{ cursor: 'pointer' }}
        />
      )}
    </div>
  );
};

export default ActionButtonRenderer;
