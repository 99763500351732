import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import {
  OfferItem,
  OfferPricingBasis,
  PricingSheetRow,
} from '../../../../../shared/types';
import { memo } from 'react';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import { DatabaseItemGroup } from '../../../../../shared/types/offers';
import { useUpdateOfferItem } from '../../../hooks/useUpdateOfferItem';

type OfferPricingBasisRendererProps =
  ICellRendererParams<OfferItem> & {
    handleItemGroupUpdate?: (itemGroup: DatabaseItemGroup) => void;
    handleMultipleRowValueUpdates?: (
      row: PricingSheetRow[],
      showNotification: boolean,
    ) => void;
  };

const selectStyles = {
  fontSize: 13,
  height: 30,
  pl: '11px',
  '&:after, &:before': {
    display: 'none',
  },
  '.MuiSelect-select.MuiSelect-select': {
    backgroundColor: 'transparent',
    pb: 0,
  },
};

export const OfferPricingBasisRenderer = memo(
  (params: OfferPricingBasisRendererProps) => {
    const {
      column,
      data,
      value,
      handleItemGroupUpdate,
      handleMultipleRowValueUpdates,
    } = params;
    const { type, offerItemId } = data || {};
    const field = column?.getColId() as keyof OfferItem;
    const { mutate: updateOfferItem } = useUpdateOfferItem();

    const isSalesGroup = params.node?.group
      ? isSalesItem(
          params.node?.allLeafChildren[0].data?.itemGroup?.type,
        )
      : false;

    // show empty cell for sales items
    if (isSalesItem(type) || isSalesGroup) {
      return <></>;
    }
    const firstItemData =
      params.node?.allLeafChildren?.length > 0
        ? params.node.allLeafChildren[0].data
        : undefined;

    const handleChange = (event: SelectChangeEvent) => {
      const value = event.target.value;
      const pricingBasis = value as OfferPricingBasis;

      if (params.node.group) {
        const updatedItems = params.node.allLeafChildren.map(
          (item) => {
            let newData = { ...item.data };

            newData.pricingBasis = pricingBasis;

            return { ...item, data: newData };
          },
        );

        const newDataArray = updatedItems.map((item) => item.data);

        if (
          handleItemGroupUpdate &&
          firstItemData?.itemGroup?.id !== undefined
        ) {
          handleItemGroupUpdate({
            ...firstItemData.itemGroup,
            pricingBasis: pricingBasis,
          });
        }

        if (handleMultipleRowValueUpdates) {
          handleMultipleRowValueUpdates(
            newDataArray as PricingSheetRow[],
            false,
          );
        }
      }

      if (value && field && !params.node.group) {
        updateOfferItem({
          id: String(offerItemId),
          item: {
            [field]: value,
          },
        });
      }
    };

    return (
      <FormControl fullWidth variant="standard">
        <Select
          sx={selectStyles}
          value={value}
          size="small"
          onChange={handleChange}
          disabled={!!params.data?.itemGroup}
        >
          <MenuItem dense value="DAY">
            Päivähinta
          </MenuItem>
          <MenuItem dense value="MONTH">
            Kuukausihinta
          </MenuItem>
        </Select>
      </FormControl>
    );
  },
);
