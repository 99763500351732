import OfferStepper from './OfferStepper';
import StepperContainer from '../StepperContainer';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { OfferDetails } from './OfferDetails';
import { OfferItems } from './OfferItems';
import {
  OfferRouterParams,
  OfferState,
  OfferStep,
} from '../../../../shared/types/offers';
import { useOfferStore } from '../../stores/offerStore';
import LoadingRenderer from '../../components/Common/LoadingRenderer';
import { useOfferSheet } from '../../hooks/useOfferSheet';
import OfferStepActionButton from './OfferStepActionButton';
import { useItems } from '../../hooks/useItems';
import { OfferPricing } from './OfferPricing';
import useSearchParams from '../../hooks/useSearchParams';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import { OfferItemDetails } from './OfferItemDetails';
import { OfferSummary } from './OfferSummary';
import { useUpdateOffer } from '../../hooks/useUpdateOffer';
import { getOfferTotalCoefficients } from '../../utils/pricingFormulas';
import { convertDatabaseOfferDatesToDate } from '../../helpers/convertDatabaseOfferDates';
import {
  calculateTotalRentalPrice,
  calculateTotalSalesPrice,
} from '../../utils/calculateTotalPrices';
import { calculateTotalPrice } from '../../../../shared/calculateTotalPrice';
import { PRICE_FACTOR } from '../../../../shared/constants';

const EditOffer = () => {
  const [activeStep, setActiveStep, clearCompareState] =
    useOfferStore((state) => [
      state.activeStep,
      state.setActiveStep,
      state.clearCompareState,
    ]);
  const params = useParams<OfferRouterParams>();
  const searchParams = useSearchParams();
  const history = useHistory();
  const setActiveStepFromSearchParams = useRef(true);
  const { id } = params;
  const { setNotification } = useContext(NotificationProvider);

  // fetch offer sheet
  const {
    data: offerSheet,
    isPending: isLoadingOffer,
    error,
  } = useOfferSheet({ id });
  // prefetch items if not already in cache, so that the Items step will load instantly
  const { data: offerSheetItems = [] } = useItems({
    includeHidden: true,
    offerSheetItemsOnly: true,
    offerSheet,
  });
  // update offer sheet mutation
  const { mutateAsync: updateOffer, isPending: isUpdatingOffer } =
    useUpdateOffer();

  const { state } = offerSheet || {};
  // combined loading flag
  const isLoading = isLoadingOffer || isUpdatingOffer;

  const isEditable =
    state === OfferState.Draft ||
    state === OfferState.InternalApproval ||
    state === OfferState.InternalRejection;

  useEffect(() => {
    if (
      (averagePriceFactor ||
        totalOfferPrice ||
        totalRentalItemPrice ||
        totalSalesItemPrice ||
        totalRamiturvaItemPrice) &&
      copyOfferSheet
    ) {
      copyOfferSheet.totalCoefficient =
        Math.round(averagePriceFactor / 10000) * 10000;
      copyOfferSheet.totalPrice = totalOfferPrice;
      copyOfferSheet.totalRentalPrice = totalRentalItemPrice;
      copyOfferSheet.totalSalesPrice = totalSalesItemPrice;
      copyOfferSheet.totalRamiturvaPrice = totalRamiturvaItemPrice;
      updateOffer({
        id,
        item: copyOfferSheet,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // clear compare state when leaving the page
  useEffect(() => () => clearCompareState(), [clearCompareState]);
  // redirect to offers listing if user isn't allowed to edit the offer,
  // the offer is removed or if the offer isn't allowed to be edited
  useEffect(() => {
    const status = error?.request?.status;
    if (status === 403) {
      history.push('/tarjoukset');
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message:
          'Käyttäjätunnuksellasi ei ole oikeuksia muokata tarjousta!',
      });
    }
    if (offerSheet && (offerSheet.removedAt || !isEditable)) {
      history.push('/tarjoukset');
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Tarjousta ei voi enää muokata!',
      });
    }
  }, [error, history, isEditable, offerSheet, setNotification]);
  // update activeStep based on search params
  useEffect(() => {
    const step = searchParams.get('t');
    if (step && state && setActiveStepFromSearchParams.current) {
      // check offer state and redirect to correct step is needed
      if (
        state === OfferState.InternalApproval &&
        Number(step) !== OfferStep.Summary
      ) {
        history.replace(
          `${history.location.pathname}?t=${OfferStep.Summary}`,
        );
        setActiveStep(OfferStep.Summary);
      } else {
        // redirect to items step if offer has no items
        if (offerSheet?.items.length === 0) {
          history.replace(
            `${history.location.pathname}?t=${OfferStep.Items}`,
          );
          setActiveStep(OfferStep.Items);
          return;
        }
        // otherwise set step from search params
        setActiveStep(Number(step));
      }
      setActiveStepFromSearchParams.current = false;
    }
  }, [
    history,
    setActiveStepFromSearchParams,
    searchParams,
    setActiveStep,
    state,
    offerSheet?.items,
  ]);

  const copyOfferSheet = offerSheet
    ? convertDatabaseOfferDatesToDate(offerSheet)
    : undefined;

  const averagePriceFactor =
    getOfferTotalCoefficients(offerSheetItems);

  const totalRentalItemPrice =
    calculateTotalRentalPrice(offerSheetItems);

  const totalSalesItemPrice =
    calculateTotalSalesPrice(offerSheetItems);

  const totalRamiturvaItemPrice = offerSheetItems.reduce(
    (acc, item) => {
      if (
        item.includeRamiturva &&
        (item.ramiturvaMonthPrice || item.ramiturvaDayPrice)
      ) {
        return (
          acc +
          calculateTotalPrice(item, 'TWODECIMALS', true) *
            PRICE_FACTOR
        );
      }
      return acc;
    },
    0,
  );

  const totalOfferPrice =
    totalRentalItemPrice +
    totalSalesItemPrice +
    totalRamiturvaItemPrice;

  const handleStepChange = useCallback(
    (nextStep: OfferStep) => {
      setActiveStep(nextStep);
      history.push(`${history.location.pathname}?t=${nextStep}`);
    },
    [history, setActiveStep],
  );

  const handleDisabledSteps = (step: number) => {
    if (state === OfferState.InternalApproval) {
      return step !== OfferStep.Summary;
    }
    return step > activeStep;
  };

  if (!offerSheet) {
    return (
      <StepperContainer>
        {isLoadingOffer ? (
          <LoadingRenderer
            label={'Ladataan tarjousta'}
            sx={{ my: 2 }}
          />
        ) : (
          <Typography color={'primary'} variant={'h5'}>
            Tarjousta ei löytynyt!
          </Typography>
        )}
      </StepperContainer>
    );
  }

  const offerSheetItemCount = offerSheet.items.length;

  return (
    <StepperContainer>
      <OfferStepper
        onStepClick={handleStepChange}
        stepDisabled={handleDisabledSteps}
      />
      {/* OFFER DETAILS */}
      {activeStep === OfferStep.Details && (
        <OfferDetails
          offerSheet={offerSheet}
          renderStepActions={(state) => {
            const {
              customerName,
              industry,
              name,
              pricingBasis,
              dealProbability,
            } = state;
            return (
              <OfferStepActionButton
                type={'NEXT'}
                loading={isLoading}
                disabled={
                  !(
                    customerName &&
                    industry &&
                    name &&
                    pricingBasis &&
                    Number.isInteger(dealProbability)
                  )
                }
                onClick={async () => {
                  if (offerSheet.pricingBasis !== pricingBasis) {
                    setNotification({
                      type: 'MODAL',
                      size: 'md',
                      severity: 'info',
                      title: `Olet vaihtanut tarjouksen hinnoitteluperustetta. Haluatko jatkaa?`,
                      message: ` `,
                      NotificationBody: () => {
                        return (
                          <>
                            <Typography>
                              Hinnoitteluperusteen vaihtaminen
                              vaikuttaa valittuihin tuotteisiin. Jos
                              olet vaihtamassa:
                            </Typography>
                            <ul style={{ textAlign: 'left' }}>
                              {[
                                'Päivä => Kuukausi: Valittujen tuotteiden hinnoitteluperusteeksi asetetaan "Kuukausi"',
                                'Kuukausi => Päivä: Valittujen tuotteiden hinnoitteluperusteeksi asetetaan "Päivä"',
                                'Päivä => Molemmat: Valituille päivätuotteille luodaan rinnalle "Kuukausi" -tuote',
                                'Kuukausi => Molemmat: Valituille kuukausituotteille luodaan rinnalle "Päivä" -tuote',
                              ].map((text) => (
                                <li key={text}>
                                  <Typography>{text}</Typography>
                                </li>
                              ))}
                              {[
                                'Molemmat => Päivä: Valitut kuukausituotteet poistetaan hinnastosta',
                                'Molemmat => Kuukausi: Valitut päivätuotteet poistetaan hinnastosta',
                              ].map((text) => (
                                <li key={text}>
                                  <Typography>
                                    <b>{text}</b>
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          </>
                        );
                      },
                      onAccept: async () => {
                        await updateOffer({ id, item: state });
                        handleStepChange(OfferStep.Items);
                      },
                    });
                  } else {
                    await updateOffer({ id, item: state });
                    handleStepChange(OfferStep.Items);
                  }
                }}
              />
            );
          }}
        />
      )}
      {/* OFFER ITEMS */}
      {activeStep === OfferStep.Items && (
        <OfferItems
          offerSheet={offerSheet}
          renderStepActions={(itemCount) => (
            <>
              <OfferStepActionButton
                type={'BACK'}
                loading={isLoading}
                onClick={() => handleStepChange(OfferStep.Details)}
              />
              <OfferStepActionButton
                type={'NEXT'}
                loading={isLoading}
                disabled={itemCount === 0}
                onClick={() => {
                  if (itemCount > 15) {
                    setNotification({
                      type: 'MODAL',
                      severity: 'info',
                      message: `Olet valinnut tarjoukselle ${itemCount} tuotetta. Oletko varma että haluat tehdä tarjouksen etkä hinnastoa?`,
                      title: 'Ennen kuin jatkat...',
                      onAccept: () =>
                        handleStepChange(OfferStep.ItemDetails),
                    });
                  } else {
                    handleStepChange(OfferStep.ItemDetails);
                  }
                }}
              />
            </>
          )}
        />
      )}
      {/* OFFER ITEM DETAILS */}
      {activeStep === OfferStep.ItemDetails && (
        <OfferItemDetails
          offerSheet={offerSheet}
          renderStepActions={() => (
            <>
              <OfferStepActionButton
                type={'BACK'}
                loading={isLoading}
                onClick={() => handleStepChange(OfferStep.Items)}
              />
              <OfferStepActionButton
                type={'NEXT'}
                loading={isLoading}
                onClick={() => handleStepChange(OfferStep.Pricing)}
              />
            </>
          )}
        />
      )}
      {/* OFFER PRICING */}
      {activeStep === OfferStep.Pricing && (
        <OfferPricing
          offerSheet={offerSheet}
          renderStepActions={() => (
            <>
              <OfferStepActionButton
                type={'BACK'}
                loading={isLoading}
                onClick={() =>
                  handleStepChange(OfferStep.ItemDetails)
                }
              />
              <OfferStepActionButton
                type={'NEXT'}
                disabled={offerSheetItemCount === 0}
                loading={isLoading}
                onClick={() => handleStepChange(OfferStep.Summary)}
              />
            </>
          )}
        />
      )}
      {/* OFFER SUMMARY */}
      {activeStep === OfferStep.Summary && (
        <OfferSummary
          offerSheet={offerSheet}
          renderStepActions={() => (
            <OfferStepActionButton
              type={'BACK'}
              disabled={state === OfferState.InternalApproval}
              loading={isLoading}
              onClick={() => handleStepChange(OfferStep.Pricing)}
            />
          )}
        />
      )}
    </StepperContainer>
  );
};

export default EditOffer;
