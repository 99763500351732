import { debounce } from 'lodash';
import { updateItemGroup } from '../../../services/itemGroups';
import { ramiturvaPriceGetter } from '../valueGetters/RamiturvaPricegetter';
import { priceGetter } from '../valueGetters/priceGetter';
import proposalPriceGetter from '../valueGetters/proposalPriceGetter';
import { ValueGetterParams } from 'ag-grid-community';
import { PRICE_FACTOR } from '../../../../../shared/constants';

const debouncedUpdateItemGroup = debounce((itemGroup) => {
  updateItemGroup(itemGroup);
}, 1000);

// Aggregator for proposal price
export const proposalPriceAggregator = (params: any) => {
  const total = params.rowNode.allLeafChildren.reduce(
    (accumulator: number, child: ValueGetterParams) => {
      const price = proposalPriceGetter(child) || 0;
      const result = price * child.data.quantity;
      return accumulator + result;
    },
    0,
  );
  return total.toFixed(2);
};

// Aggregator for price
export const priceAggregator = (params: any) => {
  const calculateTotalPrice = (child: ValueGetterParams) => {
    const price = priceGetter(child, 'TWODECIMALS') || 0;
    return Number(price) * child.data.quantity;
  };

  let total = 0;

  params.rowNode.allLeafChildren.forEach(
    (child: ValueGetterParams) => {
      if (child.data.itemGroup) {
        total += calculateTotalPrice(child);
      }
    },
  );

  const totalWithoutDecimal = Math.round(total * PRICE_FACTOR);

  if (
    params.rowNode.allLeafChildren.length > 0 &&
    params.rowNode.allLeafChildren[0]?.data?.itemGroup
  ) {
    const itemGroup = {
      ...params.rowNode.allLeafChildren[0].data.itemGroup,
      totalPrice: totalWithoutDecimal,
    };

    if (
      !params.rowNode.aggData ||
      params.rowNode.aggData.totalPrice * PRICE_FACTOR !==
        totalWithoutDecimal
    ) {
      debouncedUpdateItemGroup(itemGroup);
    }
  }

  return total.toFixed(2);
};

// Aggregator for totalPrice and rentPeriodTotalPrice
const calculateTotalPriceWithQuantity = (params: any) => {
  const sum = params.values.reduce(
    (acc: number, value: number) => acc + value,
    0,
  );
  const groupQuantity =
    params.rowNode.allLeafChildren[0]?.data &&
    params.rowNode.allLeafChildren[0].data.itemGroup?.quantity
      ? params.rowNode.allLeafChildren[0].data.itemGroup.quantity
      : 1;
  return (sum * groupQuantity).toFixed(2);
};

export const totalPriceAggregator = (params: any) => {
  return calculateTotalPriceWithQuantity(params);
};

export const rentPeriodTotalPriceAggregator = (params: any) => {
  return calculateTotalPriceWithQuantity(params);
};

// Aggregator for discount
export const discountAggregator = (params: any) => {
  const price = Number(priceAggregator(params));
  const proposalPrice = Number(proposalPriceAggregator(params));

  return (((price - proposalPrice) / proposalPrice) * 100).toFixed(2);
};

export const pricingBasisAggregator = (params: any) => {
  return params.rowNode.allLeafChildren[0]?.data?.pricingBasis || '';
};

// Aggregator for ramiturva
export const ramiturvaAggregator = (params: any) => {
  let sum = 0;

  params.rowNode.allLeafChildren.forEach(
    (item: ValueGetterParams) => {
      if (item.data.includeRamiturva) {
        const price = ramiturvaPriceGetter(item, 'TWODECIMALS');

        if (price) {
          sum += price;
        }
      }
    },
  );

  return sum;
};
