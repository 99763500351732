import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { OfferItem, PricingSheetRow } from '../../../../shared/types';
import { useMutation } from '@tanstack/react-query';
import {
  deleteItemGroup,
  updateItemGroup,
} from '../../services/itemGroups';
import GroupingGrid from './GroupingGrid';
import { isSalesItem } from '../../../../shared/helpers/isSalesItem';
import Dates from '../Steps/Components/Dates';
import GroupDialogRadioButtons from './GroupDialogRadioButtons';
import { PRICE_FACTOR } from '../../../../shared/constants';

interface EditGroupDialogProps {
  items: OfferItem[];
  handleMultipleRowValueUpdates: (selectedRows: OfferItem[]) => void;
  selectedRows: PricingSheetRow[];
  groupId?: number;
  leasePeriodStart: Date;
  leasePeriodEnd: Date;
}

const EditGroupDialog: React.FunctionComponent<
  EditGroupDialogProps
> = ({
  items,
  handleMultipleRowValueUpdates,
  selectedRows,
  groupId,
}) => {
  const {
    id = null,
    groupName = '',
    totalPrice = 0,
    pricingBasis = 'MONTH',
    quantity = 1,
    showItemsInPdf = true,
    type = 'RENTAL',
    leasePeriodStart = new Date(),
    leasePeriodEnd = new Date(),
  } = selectedRows[0]?.itemGroup ?? {};

  const [open, setOpen] = useState(false);
  const [groupedItems, setGroupedItems] = useState<OfferItem[]>([]);
  const [groupState, setGroupState] = useState({
    id,
    groupName,
    totalPrice,
    pricingBasis,
    quantity,
    showItemsInPdf,
    type,
    leasePeriodStart,
    leasePeriodEnd,
  });

  const [displayTotalPrice, setDisplayTotalPrice] =
    useState(totalPrice);
  const [displayGroupName, setDisplayGroupName] = useState(groupName);
  const [displayPricingBasis, setDisplayPricingBasis] =
    useState(pricingBasis);

  const isGroup =
    selectedRows.length > 0 &&
    selectedRows.every(
      (item) => item.itemGroup && item.itemGroup.id === groupId,
    );

  useEffect(() => {
    setGroupState({
      id,
      groupName,
      totalPrice,
      pricingBasis,
      quantity,
      showItemsInPdf,
      type,
      leasePeriodStart,
      leasePeriodEnd,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    const calculateTotalPrice = () => {
      return groupedItems.reduce((accumulator, currentItem) => {
        const price =
          (groupState.pricingBasis === 'MONTH'
            ? currentItem.monthPrice ?? 0
            : currentItem.dayPrice ?? 0) * currentItem.quantity;
        return accumulator + price / PRICE_FACTOR;
      }, 0);
    };

    setDisplayTotalPrice(
      parseFloat(calculateTotalPrice().toFixed(2)),
    );
  }, [groupedItems, groupState]);

  const remainingItems = items.filter(
    (item) =>
      isSalesItem(item.type) === isSalesItem(groupState.type) &&
      !groupedItems.some(
        (selectedItem) =>
          selectedItem.offerItemId === item.offerItemId,
      ),
  );

  const {
    isPending: isLoadingUpdate,
    mutate: updateItemGroupMutation,
  } = useMutation({
    mutationFn: updateItemGroup,
    onSuccess: () => {
      if (groupedItems.length === 0 && groupState.id) {
        deleteItemGroupMutation({ groupId });
      }
      const modifiedItems = items
        .filter(
          (item) =>
            !groupedItems.some(
              (selectedItem) =>
                selectedItem.offerItemId === item.offerItemId,
            ),
        )
        .map((item) => ({
          ...item,
          itemGroupId:
            groupId === item.itemGroupId
              ? undefined
              : item.itemGroupId,
          itemGroup: undefined,
        }));

      const newRows = groupedItems.map((item: OfferItem) => {
        const {
          leasePeriodStart,
          leasePeriodEnd,
          ...restGroupState
        } = groupState;

        return {
          ...item,
          itemGroupId: groupId,
          leasePeriodStart,
          leasePeriodEnd,
          itemGroup: {
            ...restGroupState,
            leasePeriodStart,
            leasePeriodEnd,
          },
          pricingBasis: groupState.pricingBasis,
        };
      });
      const mergedRows: OfferItem[] = [
        ...newRows,
        ...modifiedItems,
      ] as OfferItem[];

      handleMultipleRowValueUpdates([...mergedRows]);
    },
    onSettled: () => {
      setOpen(!isLoadingUpdate);
    },
  });

  const {
    isPending: isLoadingDelete,
    mutate: deleteItemGroupMutation,
  } = useMutation({
    mutationFn: deleteItemGroup,
    onSuccess: () => {
      const filteredItems = items.filter((item) => {
        // Check if the item's groupId matches the specified groupId
        return item.itemGroup?.id === groupId;
      });

      const newRows = filteredItems.map((item: OfferItem) => {
        return {
          ...item,
          itemGroupId: undefined,
          itemGroup: undefined,
        };
      });
      handleMultipleRowValueUpdates([...newRows]);
    },
    onSettled: () => {
      setOpen(!isLoadingDelete);
    },
  });

  const handleGroupNameChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setDisplayGroupName(e.target.value);
    setGroupState({
      ...groupState,
      groupName: e.target.value,
    });
  };

  const openDialog = () => {
    const newSelectedItems = items.filter(
      (item) => item.itemGroup?.id === groupId,
    );
    setGroupedItems(
      newSelectedItems.map((item) => ({
        ...item,
        selected: item.selected ?? false,
      })),
    );
    setDisplayGroupName(groupName);
    setDisplayPricingBasis(pricingBasis);
    setOpen(!open);
  };

  const handleShowItemsInPdfChange = (value: boolean) => {
    setGroupState({
      ...groupState,
      showItemsInPdf: value,
    });
  };

  const handlePricingBasisChange = (basis: 'DAY' | 'MONTH') => {
    setDisplayPricingBasis(basis);
    setGroupState({
      ...groupState,
      pricingBasis: basis,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setGroupState({
      ...groupState,
      leasePeriodStart: new Date(date),
    });
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setGroupState({
      ...groupState,
      leasePeriodEnd: new Date(date),
    });
  };

  const handleSaveButtonClick = () => {
    const updatedGroupState = {
      ...groupState,
      id: groupState.id || 0,
      totalPrice: displayTotalPrice
        ? displayTotalPrice * PRICE_FACTOR
        : 0,
    };
    updateItemGroupMutation(updatedGroupState);
  };

  const handleDeleteButtonClick = () => {
    deleteItemGroupMutation({ groupId });
  };

  const handleCancelButtonClick = () => {
    setOpen(false);
  };

  const isSaveButtonDisabled = () => {
    const currentDate = new Date();
    const startDate = new Date(groupState.leasePeriodStart);
    const endDate = new Date(groupState.leasePeriodEnd);

    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return (
      groupedItems.length === 0 ||
      groupState.groupName === '' ||
      (groupState.type === 'RENTAL' &&
        (!groupState.leasePeriodStart ||
          !groupState.leasePeriodEnd ||
          startDate < currentDate ||
          endDate <= startDate ||
          endDate < currentDate))
    );
  };

  return (
    <>
      <DefaultTooltip title="Muokkaa olemassa olevaa tuoteryhmää">
        <Button
          disabled={selectedRows.length === 0 || !isGroup}
          startIcon={<Filter9PlusIcon />}
          onClick={openDialog}
          sx={{ height: 26 }}
          size="small"
          variant="outlined"
        >
          Muokkaa ryhmää
        </Button>
      </DefaultTooltip>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            maxHeight: '95vh',
          },
        }}
      >
        <DialogTitle>Muokkaa ryhmää</DialogTitle>
        <Fade in={open}>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                gap: 16,
              }}
            >
              <Box>
                <TextField
                  label="Ryhmän nimi"
                  placeholder="Ryhmän nimi"
                  sx={{ my: 1, minWidth: 300 }}
                  onChange={handleGroupNameChange}
                  value={displayGroupName}
                />
                <Box sx={{ my: 1 }}>
                  <Dates
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    startDate={
                      groupState.leasePeriodStart ?? new Date()
                    }
                    endDate={groupState.leasePeriodEnd ?? new Date()}
                    minStartDate={new Date()}
                    startDateLabel={'Tuoteryhmän vuokra-aika alkaa'}
                    endDateLabel={'Tuoteryhmän vuokra-aika loppuu'}
                    disabled={groupState.type === 'SALES'}
                  />
                </Box>
              </Box>
              <Box>
                <GroupDialogRadioButtons
                  label="Ryhmän hinnoitteluperuste:"
                  value={displayPricingBasis}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handlePricingBasisChange(
                      event.target.value as 'MONTH' | 'DAY',
                    )
                  }
                  options={[
                    { label: 'Päivähinta', value: 'DAY' },
                    { label: 'Kuukausihinta', value: 'MONTH' },
                  ]}
                  disabled={false}
                />
                <GroupDialogRadioButtons
                  label="Näytä tuotteet PDF:llä:"
                  value={groupState.showItemsInPdf.toString()}
                  onChange={(event) =>
                    handleShowItemsInPdfChange(
                      event.target.value === 'true',
                    )
                  }
                  options={[
                    { label: 'Kyllä', value: 'true' },
                    { label: 'Ei', value: 'false' },
                  ]}
                  disabled={false}
                />
                <Box>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormLabel focused={true}>
                        Ryhmän tuotteiden tyyppi:
                      </FormLabel>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <Typography marginY={1}>
                        {groupState.type === 'RENTAL'
                          ? 'Vuokratuotteet'
                          : 'Myynti- ja palvelutuotteet'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormLabel focused>
                        Yhden ryhmän hinta:
                      </FormLabel>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <Typography marginY={1}>
                        {displayTotalPrice} €
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormLabel focused>
                        Ryhmien lukumäärä:
                      </FormLabel>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <Typography marginY={1}>{quantity}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box>
              <GroupingGrid
                groupedItems={groupedItems}
                remainingItems={remainingItems}
                setGroupedItems={setGroupedItems}
                pricingBasis={displayPricingBasis || ''}
                groupName={groupState.groupName || ''}
                groupType={groupState.type || ''}
              />
            </Box>
            <DialogActions
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Button
                  color="inherit"
                  onClick={handleCancelButtonClick}
                >
                  Peruuta
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{ mr: 2 }}
                  variant="contained"
                  color="error"
                  onClick={handleDeleteButtonClick}
                >
                  Poista ryhmä
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveButtonClick}
                  disabled={isSaveButtonDisabled()}
                >
                  Tallenna
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Fade>
      </Dialog>
    </>
  );
};

export default EditGroupDialog;
